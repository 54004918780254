//import _ from 'lodash';
import "../sass/styles.scss";

//console.log('Localize: ', localize);
//console.log('Webpack JS!');

(function($){

  /**
   * VARIABLES
   */
  const htmlSpinerLoading = '<p class="loading"><i class="icon icon-loading" title="Cargando..."></i></p>';

  /**
   * EVENTOS AND FORMACIÓN
   * ARROWS DATE
   */
  const months = {
    '01':'Enero',
    '02':'Febrero',
    '03':'Marzo',
    '04':'Abril',
    '05':'Mayo',
    '06':'Junio',
    '07':'Julio',
    '08':'Agosto',
    '09':'Septiembre',
    '10':'Octubre',
    '11':'Noviembre',
    '12':'Diciembre'
  };

  function getFormattedDate(date) {
    var year = date.getFullYear();
    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;
    var day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;
    return day + '-' + month + '-' + year;
  }

  function updateDate(this_){
    var date_split, date, start, was, new_;
    var filterYearMonth = $('.date_interval').attr('data-filteryearmonth');

    if( this_.hasClass('arrow_left') ){
      start = this_.parent().attr('data-start');
      was = new Date(start.replace( /(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"));
      new_ = new Date(was.setMonth(was.getMonth() - 1));
      $('.date_interval').attr('data-start',  getFormattedDate(new_));
      $('.date_interval').attr('data-end',  (new Date(new_.getFullYear(), new_.getMonth() + 1, 0)).getDate() + '-' + getFormattedDate(new_).substring(3,10));
    }else if( this_.hasClass('arrow_right') ){
      start = this_.parent().attr('data-start');
      was = new Date(start.replace( /(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"));
      new_ = new Date(was.setMonth(was.getMonth() + 1));
      $('.date_interval').attr('data-start',  getFormattedDate(new_));
      $('.date_interval').attr('data-end',  (new Date(new_.getFullYear(), new_.getMonth() + 1, 0)).getDate() + '-' + getFormattedDate(new_).substring(3,10));
    }
  
    date = $('.date_interval').attr('data-start');
    date_split = date.split('-');

    var aux = date_split[2]+''+date_split[1];
    if(aux > filterYearMonth){
      $('.filter-date .arrow_left').removeClass('disabled');
      $('.filter-date .arrow_left').prop("disabled", false);
    }else {
      $('.filter-date .arrow_left').addClass('disabled');
      $('.filter-date .arrow_left').prop("disabled", true);
    }

    $('.date_interval .months_value').text( months[date_split[1]] + ' ' + date_split[2] );
  }

  /**
   * EVENTOS
   */
  $('#filterDate button.arrow_left, #filterDate button.arrow_right').click(function(){
    updateDate($(this));
    taxonomyFilter();
  });

  /**
   * FORMACIÓN
   */
  $('#filterDateFormacion button.arrow_left, #filterDateFormacion button.arrow_right').click(function(){
    updateDate($(this));

    if($('.date_interval').length){
      var date = $('.date_interval').attr('data-start');
      var date_split = date.split('-');
      var filter = date_split[2]+''+date_split[1];
      
      //console.log(filter);

      $('.actualidad .filter-actualidad__overlay').addClass('active');

      document.location = '/formacion-y-eventos/formacion/mes/' + filter + '/';

    }
  });

  /**
   * SHOW/HIDE FILTERS
   */
  if($('.filter-mobile').length){

    if(!$('.filter-mobile').hasClass('formacion')){
      $( window ).resize(function() {
        if($( window ).width() > 980){
          $('.filter-actualidad').css('display', 'block');
        }else{
          $('.filter-actualidad').css('display', 'none');
        }
      });
    }

    $('.filter-mobile .filtrar').click(function(event){
      event.preventDefault();
      $('.filter-actualidad').slideToggle('800');
      $('.formacionesyeventos-formacion__grid').toggleClass('filter-open');
      $(this).find('i.arrow').toggleClass('icon-plus icon-close');
    });

  }

  /**
   * AJAX CALL GET "Provincias"
   */
  function getProvincias(idPais) {
    //console.log('GET PROVINCIAS', idPais);
    var originalTextField = '';

    $.ajax({ 
      data: {action:'get_provincias', idPais:idPais, security:localize.ajax_nonce},
      type: 'post',
      url: localize.ajaxurl,
      beforeSend: function(){
        originalTextField = $('select[name=IdProvincia]').find('option:first').text();
        $('select[name=IdProvincia]').find('option:not(:first)').remove();
        $('select[name=IdMunicipio]').find('option:not(:first)').remove();
        $('select[name=IdProvincia]').find('option:first').text('Cargando datos...');
      },
      success: function(data) {
        //console.log(data);
        var obj = $.parseJSON(data);
        //console.log(obj);
        $('select[name=IdProvincia]').find('option:first').text(originalTextField);
        $.each( obj, function( key, value ) {
            $('select[name=IdProvincia]').append(new Option(value.DesProvincia, value.IdProvincia));
        });
      },
      error: function(data) {
        //console.log(data);
        //$('select[name=IdProvincia]').find('option').remove();
        $('select[name=IdProvincia]').find('option:first').text(originalTextField);
        $('select[name=IdProvincia]').append(new Option(data.responseJSON.data.message, ''));
      },
      complete: function() {
        //console.log('PROVINCIAS DONE!');
      }
    });
  }

  /**
   * AJAX CALL GET "Municipios"
   */
  function getMunicipios(IdProvincia) {
    //console.log('GET MUNICIPIOS', IdProvincia);
    var originalTextField = '';

    $.ajax({ 
      data: {action:'get_municipios', IdProvincia:IdProvincia, security:localize.ajax_nonce},
      type: 'post',
      url: localize.ajaxurl,
      beforeSend: function(){
        originalTextField = $('select[name=IdMunicipio]').find('option:first').text();
        $('select[name=IdMunicipio]').find('option:not(:first)').remove();
        $('select[name=IdMunicipio]').find('option:first').text('Cargando datos...');
      },
      success: function(data) {
        //console.log(data);
        var obj = $.parseJSON(data);
        //console.log(obj);
        $('select[name=IdMunicipio]').find('option:first').text(originalTextField);
        $.each( obj, function( key, value ) {
            $('select[name=IdMunicipio]').append(new Option(value.DesMunicipio, value.IdMunicipio));
        });
      },
      error: function(data) {
        //console.log(data);
        //$('select[name=IdMunicipio]').find('option').remove();
        $('select[name=IdMunicipio]').find('option:first').text(originalTextField);
        $('select[name=IdMunicipio]').append(new Option(data.responseJSON.data.message, ''));
      },
      complete: function() {
        //console.log('MUNICIPIOS DONE!');
      }
    });
  }

  /**
   * AJAX CALL GET "Provincias" 2
   */
  function getProvincias2(idPais, fieldToUpdate) {
    //console.log('GET PROVINCIAS 2', idPais);
    var originalTextField, fieldPoblacionToUpdate = '';


    $.ajax({ 
      data: {action:'get_provincias', idPais:idPais, security:localize.ajax_nonce},
      type: 'post',
      url: localize.ajaxurl,
      beforeSend: function(){
        fieldPoblacionToUpdate = $('select.api-IdProvincia[name^='+fieldToUpdate+']').attr('data-fieldToUpdate');
        originalTextField = $('select.api-IdProvincia[name^='+fieldToUpdate+']').find('option:first').text();
        $('select.api-IdProvincia[name='+fieldToUpdate+']').find('option:not(:first)').remove();
        $('select.api-IdPoblacion[name='+fieldPoblacionToUpdate+']').find('option:not(:first)').remove();
        $('select.api-IdProvincia[name^='+fieldToUpdate+']').find('option:first').text('Cargando datos...');
      },
      success: function(data) {
        //console.log(data);
        var obj = $.parseJSON(data);
        //console.log(obj);
        $('select.api-IdProvincia[name^='+fieldToUpdate+']').find('option:first').text(originalTextField);
        $.each( obj, function( key, value ) {
            $('select.api-IdProvincia[name='+fieldToUpdate+']').append(new Option(value.DesProvincia, value.IdProvincia));
        });
      },
      error: function(data) {
        //console.log(data);
        //$('select.api-IdProvincia[name='+fieldToUpdate+']').find('option').remove();
        $('select.api-IdProvincia[name^='+fieldToUpdate+']').find('option:first').text(originalTextField);
        $('select.api-IdProvincia[name='+fieldToUpdate+']').append(new Option(data.responseJSON.data.message, ''));
      },
      complete: function() {
        //console.log('PROVINCIAS DONE!');
      }
    });
  }

  /**
   * AJAX CALL GET "Municipios" 2
   */
  function getMunicipios2(IdProvincia, fieldToUpdate) {
    //console.log('GET MUNICIPIOS 2', IdProvincia);
    var originalTextField = '';
  
    $.ajax({
      data: {action:'get_municipios', IdProvincia:IdProvincia, security:localize.ajax_nonce},
      type: 'post',
      url: localize.ajaxurl,
      beforeSend: function(){
        originalTextField = $('select.api-IdPoblacion[name^='+fieldToUpdate+']').find('option:first').text();
        $('select.api-IdPoblacion[name='+fieldToUpdate+']').find('option:not(:first)').remove();
        $('select.api-IdPoblacion[name^='+fieldToUpdate+']').find('option:first').text('Cargando datos...');
      },
      success: function(data) {
        //console.log(data);
        var obj = $.parseJSON(data);
        //console.log(obj);
        $('select.api-IdPoblacion[name^='+fieldToUpdate+']').find('option:first').text(originalTextField);
        $.each( obj, function( key, value ) {
            $('select.api-IdPoblacion[name='+fieldToUpdate+']').append(new Option(value.DesMunicipio, value.IdMunicipio));
        });
      },
      error: function(data) {
        //console.log(data);
        //$('select.api-IdPoblacion[name='+fieldToUpdate+']').find('option').remove();
        $('select.api-IdPoblacion[name^='+fieldToUpdate+']').find('option:first').text(originalTextField);
        $('select.api-IdPoblacion[name='+fieldToUpdate+']').append(new Option(data.responseJSON.data.message, ''));
      },
      complete: function() {
        //console.log('MUNICIPIOS DONE!');
      }
    });
  }

  /**
   * AJAX CALL GET Especialidades By IdEscuela
   */
  /*
  function getEspecialidadesByIdEscuela(elem, fieldToUpdate, especialidadNum) {
    console.log('GET ESPECIALIDADES BY IdEscuela: ', elem.value);
    //console.log('This: ', elem);
    //console.log('IdEscuela: ', elem.value);
    //console.log('fieldToUpdate: ', fieldToUpdate);
    //console.log('especialidadNum: ', especialidadNum);

    var IdEscuela = elem.value;
    var elemToUpdate = $('.carrera[data-especialidadnum=' + especialidadNum + ']').find('select.api-IdEspecialidad[name^='+fieldToUpdate+']');
    //console.log(elemToUpdate);

    $.ajax({ 
      data: {action:'get_especialidades_by_idescuela', IdEscuela:IdEscuela, security:localize.ajax_nonce},
      type: 'post',
      url: localize.ajaxurl,
      beforeSend: function(){
        //$('.btn.addEspecialidad').prop('disabled', true);
        elemToUpdate.find('option:not(:first)').remove();
        elemToUpdate.find('option:first').delay(800).text('Cargando datos...');
        $('span[id^=select2-IdEspecialidad]').delay(800).html('Cargando datos...');
      },
      success: function(data) {
        //console.log(data);
        var obj = $.parseJSON(data);
        //console.log(obj);
        //$('.btn.addEspecialidad').prop('disabled', false);
        elemToUpdate.find('option:first').text('Especialidad*');
        $('span[id^=select2-IdEspecialidad]').html('Especialidad*');

        $.each( obj, function( key, value ) {
            elemToUpdate.append(new Option(value.DesEspecialidad, value.IdEspecialidad));
        });
      },
      error: function(data) {
        console.log(data);
      },
      complete: function() {
        
      }
    });
  }
  */

  /**
   * "EVENTOS" AND "NOTICIAS"
   * TAXONOMY FILTER
   */
  function taxonomyFilter(){
    //console.log('TAXONOMY FILTER');

    var filter = {};
    filter['taxonomies'] = {};
    $('#taxonomyFilter input[type=checkbox]').each(function( index ) {
      if($(this).is(':checked')){
        var name = $(this).attr('name'); // Get name of this checkbox

        if (filter['taxonomies'][name]) {
          filter['taxonomies'][name].push($(this).val()); // Push value
        } else {
          filter['taxonomies'][name] = [$(this).val()]; // Create array and push value
        }
      }
    });

    filter['sedes'] = {
      'taxonomy' : $('#filterSedes select[id=sedes]').attr('name'),
      'terms' : $('#filterSedes select[id=sedes] option').filter(':selected').val()
    }

    if($('.date_interval').length){
      var date = $('.date_interval').attr('data-start');
      var date_split = date.split('-');

      filter['date'] = {
        'month' : date_split[1],
        'year' : date_split[2]
      }
    }

    filter['action'] = 'taxonomy_filter';
    filter['ajax_nonce'] = localize.ajax_nonce;
    filter['post_type'] = $('#taxonomyFilter').data('type');
    filter['category'] = $('#taxonomyFilter').data('category');
    //filter['query'] = localize.posts;
    //filter['page'] = localize.current_page;

    //console.log(filter);
 
    $.ajax({
      url : localize.ajaxurl, // AJAX handler
      data : filter,
      type : 'POST',
      beforeSend : function ( xhr ) {
        //console.log('TAXONOMY FILTER BEFORE SEND');
        $('.actualidad .filter-actualidad__overlay').addClass('active');
      },
      success : function( data ){
        //console.log(data);
        $('.actualidad #contenido_articulos').html(data);
      }
    })
    .done(function(data) {
      //console.log('DONE!');
      $('.actualidad .coiimtheme_loadmore').css('display', 'none');
      $('.actualidad .filter-actualidad__overlay').removeClass('active');
    })
    .fail(function(error) {
      console.error(error)
    });
    
  }

  /**
   * EVENTOS AND NOTICIAS
   * SEDES FILTER
   */
  function sedesFilter(){
    //console.log('SEDES FILTER');
    taxonomyFilter();
  }

  /**
   * SCROLL TO ANCHOR
   */
  function scrollToAnchor(elem, offset = 0){
    var aTag = $(elem);
    var offset = aTag.offset().top - offset;
    $('html,body').animate({scrollTop: offset}, 'slow');
  }

  /**
   * FADE IN "ELEMENT"
   */
  function mostrarElem(elem){
    $(elem).fadeIn(800);
  }

  /**
   * FORMS STEPS
   */
  function showFormSteps(step){
    //alert('form steps:'+step);
    $('div [id^=step-]').css('display', 'none');
    $('.form-steps__header li').removeClass('active');
    $('#step-'+step).fadeIn(600, function(){
      $([document.documentElement, document.body]).animate({
        scrollTop: $('.form-steps__header').offset().top
      }, 300);

      $('.form-steps__header li').eq(step - 1).addClass('active');
    });
  }

  /**
   * FILTERS FORMACIÓN
   */
  function filterFormacion(){
    $('.actualidad .filter-actualidad__overlay').addClass('active');
    $('#listado-formaciones .item').hide();
  
    //GET SELECTED CATEGORIES
    var selectedCategories = [];
    $('#taxonomyFilterFormacion input:checked').each(function() {
      selectedCategories.push($(this).val().toLowerCase());
    });
    //console.log(selectedCategories);

    /*
      var selectedCategoriesArray = [];
      var selectedTermsArray = [];
      var term = '';
      $('#taxonomyFilterFormacion input:checked').each(function() {
        
        var aux = $(this).prop('name');

        if(term == aux || term == ''){
          selectedTermsArray.push($(this).val().toLowerCase());
        }else{
          selectedTermsArray = [];
          selectedTermsArray.push($(this).val().toLowerCase());
        }
        term = $(this).prop('name');
        //selectedCategoriesArray[term] = selectedTermsArray;
        selectedCategoriesArray[term] = new Array (selectedTermsArray); 

      });

      var myObj = Object.assign({}, selectedCategoriesArray);
      //console.log(Object.keys(myObj).length);
      //console.log(myObj);
    
      for (const [key, value] of Object.entries(myObj)) {
        //console.log(`${key}: ${value}`);
      }
    */

    //GET SELECTED SEDE
    const filterSede = $('#filterSedesFormacion select[name=sedes]').find('option:selected');
    var sede = filterSede.text().toLowerCase();
    var sedeValue = filterSede.val();
    //console.log(sedeValue + ' : ' + sede);
  
    $('#listado-formaciones .item').filter(function() {
      if(selectedCategories.length){
  
        var item = $(this);
        //console.log('item areas: ', item.data('areasdepublicacion').toLowerCase());
  
        var points = 0;
        selectedCategories.forEach(function(category){
          //console.log(item);
          if(sedeValue){
            if(item.data('areasdepublicacion').toLowerCase().indexOf(category) > -1 && item.data('sede').toLowerCase().indexOf(sede) > -1){
              //item.show();
              points++;
            }
          }else{
            if(item.data('areasdepublicacion').toLowerCase().indexOf(category) > -1){
              //item.show();
              points++;
            }
          }

          if(points >= selectedCategories.length){
            item.show();
          }
        });
        
      }else{
        if(sedeValue){
          var item = $(this);
          if(item.data('sede').toLowerCase().indexOf(sede) > -1){
            item.show();
          }
        }else{
          $('#listado-formaciones .item').show();
        }
      }
    });

    var numVisibleItems = $('#listado-formaciones .item:visible').length;
    //console.log('numVisibleItems: ', numVisibleItems);
    if(!numVisibleItems){
      $('#listado-formaciones p.sin-resultados').remove();
      $('#listado-formaciones').append('<p class="sin-resultados">No existen resultados para los criterios de búsqueda.</p>');
      //$('#filterReset').addClass('hidden');
    }else{
      $('#listado-formaciones p.sin-resultados').remove();
      //$('#filterReset').removeClass('hidden');
    }

    $('.actualidad .filter-actualidad__overlay').removeClass('active');

  }

  /**
   * VALIDATE DATE
   * @param {*} form 
   */
  function validateInputDate(form){
    const date_dia = form.find('input.isValidDateDay').val();
    const date_mes = form.find('input.isValidDateMonth').val();
    const date_anio = form.find('input.isValidDateYear').val();

    if(date_dia && date_mes && date_anio){
      const date = date_mes + '/' + date_dia + '/' + date_anio;

      console.log('FECHA: ', date);

      if(!isValidDate(date)){
        console.log('FECHA KO');
        form.find('input.isValidDateDay, input.isValidDateMonth, input.isValidDateYear').addClass('invalid');
        form.find('input.isValidDateDay, input.isValidDateMonth, input.isValidDateYear').get(0).setCustomValidity('La fecha no es válida.');
      }else{
        console.log('FECHA OK');
        form.find('input.isValidDateDay, input.isValidDateMonth, input.isValidDateYear').removeClass('invalid');
        form.find('input.isValidDateDay, input.isValidDateMonth, input.isValidDateYear').get(0).setCustomValidity('');
      }
    }
  }

  /**
   * JQUERY READY
   */
  $( document ).ready(function() {
    console.log('FRONT-END "WEB PUBLICA" JQUERY READY');
    console.log('FRONT-END "WEB PUBLICA" NONCE: ', localize.ajax_nonce);

    /**
     * GET HEADER HEIGHT
     */
    var barTopHeight = $('.bar-top').height();
    var headerHeight = $('header').height();
    var totalHeaderHeight = barTopHeight + headerHeight;

    /**
     * MENU/HEADER
     * Hide submenu if not exist.
     */
    if(!$('.sub-nav .sub-nav__content').length) {
      $('.header__content.header__content--border-top').css('display', 'none');
    }

    /**
     * MENU/HEADER
     * MAKE IT BLANK/COLOR ON SCROLL
     */
    $(window).scroll(function(){
      var scroll = $(window).scrollTop();
      if (scroll > 300) {
        $('body.home header').addClass('is-white');
        $('body.home .logo__white').css('display', 'none');
        $('body.home .logo__color').css('display', 'block');
      }else{
        $('body.home header').removeClass('is-white'); 
        $('body.home .logo__white').css('display', 'block');	
        $('body.home .logo__color').css('display', 'none');
      }
    });

    /**
     * MENU/HEADER
     * SEARCH
     */
    $('header .nav .nav__content--buscador a').on('click', function(event){
      event.preventDefault();
      //console.log('Buscador click');
      if(!$(this).hasClass('buscar')){
        $(this).parents().find('.nav').addClass('buscando');
        $(this).parents().find('.busqueda').focus();
        $(this).addClass('buscar');
      }else if($(this).parents().find('.busqueda').val()){
        var q = $(this).parents().find('.busqueda').val();
        var nonce = $(this).parents().find('.busqueda').data('nonce');
        location.href = '/resultados-busqueda/?q='+q+'&nonce='+nonce;
      }else{
        $(this).parents().find('.busqueda').focus();
      }
    });

    $('header .nav .nav__content--buscador input.busqueda').on('focusout', function(event){
      //console.log('Buscador focusout');
      //console.log(event);
      if(!event.relatedTarget){
        $(this).parents().find('.nav').removeClass('buscando');
        $(this).parents().find('a').removeClass('buscar');
      }
    });

    $('header .nav .nav__content--buscador input.busqueda').on('keypress',function(event) {
      if(event.which == 13) {
        $('header .nav .nav__content--buscador a').trigger('click');
      }
    });

    /**
     * SLIDER HOME
     * Configuration.
     */
    if($('.slider.slider--home').length) {
      //console.log('SLIDER JQUERY READY');
      $('.slider.slider--home').slick({
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnFocus: false,
        pauseOnHover: false,
        infinite: true,
        speed: 1000,
        fade: true,
        cssEase: 'ease-in-out',
        nextArrow: '.slider--home__btn--next',
        prevArrow: '.slider--home__btn--prev'
      });
      $('.slider.slider--home').css('visibility', 'visible');
    }

    /**
     * FORM BANNER NEWSLETTER
     */
    if($('form.banner-newsletter__form').length) {
      const form = $('form.banner-newsletter__form');

      //Submit form
      form.submit(function(e) {
        e.preventDefault();

        //var $form = $(this);
        var email = form.find('input[name=email]').val();

        form.find('.banner-newsletter__icon-input').removeClass('icon-plane').addClass('icon-loading');

        $.ajax({ 
          data: {action:'newsletter_form', email:email, security:localize.ajax_nonce},
          type: 'post',
          url: localize.ajaxurl,
          success: function(data) {
            //console.log(data);

            form.find('.banner-newsletter__msg').css('display', 'block');
            if(data.success && data.data.result == 'ok'){
              form.trigger('reset');
              form.find('.banner-newsletter__msg p').addClass('success').html(data.data.message);
            }else {
              form.find('.banner-newsletter__msg p').addClass('error').html(data.data.message);
            }
          },
          error: function(data) {
            console.log(data);
            form.find('.banner-newsletter__msg').css('display', 'block');
            form.find('.banner-newsletter__msg p').addClass('error').html('No hemos podido enviar el formulario, por favor, inténtelo de nuevo más tarde.');
          },
          complete: function() {
            form.find('.banner-newsletter__icon-input').removeClass('icon-loading').addClass('icon-plane');
            setTimeout(function(){
              form.find('.banner-newsletter__msg').css('display', 'none');
              form.find('.banner-newsletter__msg p').removeClass('success').removeClass('error').html('');
            }, 10000);
          }
        });
      });

      //Enter and click to submit form
      $('form.banner-newsletter__form .banner-newsletter__icon-input').on('click', function() {
        $(this).closest('form').find('input[type=submit]').click();
      });
      form.on('keydown', function(e) {
        if ( e.which == 13 ) {
          e.preventDefault();
          $(this).find('input[type=submit]').click();
        }
      });
    }

    /**
     * FORM FOOTER NEWSLETTER
     */
    if($('form.footer-bottom__form-newsletter').length) {
      const form = $('form.footer-bottom__form-newsletter');

      //Submit form
      form.submit(function(e) {
        e.preventDefault();

        //var $form = $(this);
        var email = form.find('input[name=email]').val();
        
        form.find('.footer-bottom__icon-input').removeClass('icon-plane').addClass('icon-loading');

        $.ajax({ 
          data: {action:'newsletter_form', email:email, security:localize.ajax_nonce},
          type: 'post',
          url: localize.ajaxurl,
          success: function(data) {
            //console.log(data);

            form.find('.footer-bottom__msg').css('display', 'block');
            if(data.success && data.data.result == 'ok'){
              form.trigger('reset');
              form.find('.footer-bottom__msg p').addClass('success').html(data.data.message);
            }else {
              form.find('.footer-bottom__msg p').addClass('error').html(data.data.message);
            }
          },
          error: function(data) {
            console.log(data);
            form.find('.footer-bottom__msg').css('display', 'block');
            form.find('.footer-bottom__msg p').addClass('error').html('No hemos podido enviar el formulario, por favor, inténtelo de nuevo más tarde.');
          },
          complete: function() {
            form.find('.footer-bottom__icon-input').removeClass('icon-loading').addClass('icon-plane');
            setTimeout(function(){
              form.find('.footer-bottom__msg').css('display', 'none');
              form.find('.footer-bottom__msg p').removeClass('success').removeClass('error').html('');
            }, 10000);
          }
        });
      });

      //Enter and click to submit form
      $('form.footer-bottom__form-newsletter .footer-bottom__icon-input').on('click', function() {
        $('form.footer-bottom__form-newsletter input[type=submit]').click();
      });
      form.on('keydown', function(e) {
        if ( e.which == 13 ) {
          e.preventDefault();
          $(this).find('input[type=submit]').click();
        }
      });
    }

    /**
     * INLINE DATE PICKER
     */
    var dayNames = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
    var monthNames = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    var monthNamesShort = ['ene.', 'feb.', 'mar.', 'abr.', 'may.', 'jun.', 'jul.', 'ago.', 'sep.', 'oct.', 'nov.', 'dic.'];
    var dayNamesShort = ['dom.', 'lun.', 'mar.', 'mie.', 'jue.', 'vie.', 'sab.'];
    var dayNamesMin = ['D', 'L', 'M', 'M', 'J', 'V', 'S'];

    if($('.datepicker').length) {
      $('.datepicker').datepicker({
        altField: '#FechaRealizacion',
        //altFormat: 'yy-mm-dd',
        dateFormat: 'yy-mm-dd',
        firstDay: 1,
        nextText: 'Siguiente',
        prevText: 'Anterior',
        dayNames: dayNames,
        monthNames: monthNames,
        monthNamesShort: monthNamesShort,
        dayNamesShort: dayNamesShort,
        dayNamesMin: dayNamesMin,
        onSelect: function(selected, event) {
          var elemento = document.getElementById('FechaRealizacion');
          elemento.classList.remove('invalid'); 
          elemento.setCustomValidity('');
        }
      });
      $('.datepicker').datepicker('setDate', '');
    }

    if($('.solicitud-alta-colegiado .datepicker2').length){
      $('.solicitud-alta-colegiado .datepicker2').datepicker({
        dateFormat: 'yy-mm-dd',
        firstDay: 1,
        nextText: 'Siguiente',
        prevText: 'Anterior',
        dayNames: dayNames,
        monthNames: monthNames,
        monthNamesShort: monthNamesShort,
        dayNamesShort: dayNamesShort,
        dayNamesMin: dayNamesMin,
        changeYear: true,
        //minDate: "-20Y",
        maxDate: "+0D"
      });
    }

    /**
     * INLINE SELECT2
     */
    $('.select2').select2({
      dropdownAutoWidth: true,
      language: "es"
    });

    //SELECT2 SEARCH FOCUS
    $(document).on('select2:open', () => {
      document.querySelector('.select2-search__field').focus();
    });

    /**
     * CHECKBOX ACEPTAR CONDICIONES FOR ALL FORMS
     */
    $(document).on('click', 'form input[name=aceptar_condiciones]', function(event) {
      //console.log('Aceptar condiciones click');
      //console.log($(this).is(':checked'));

      if($(this).is(':checked')){
        $(this).parents('form').find('button[type=submit]').removeClass('disabled');
        $(this).parents('form').find('button[type=submit]').prop('disabled', false);
      }else{
        $(this).parents('form').find('button[type=submit]').addClass('disabled');
        $(this).parents('form').find('button[type=submit]').prop('disabled', true);
      }
    });

    /**
     * FORM SOLICITUD PERITO
     */
    if($('form.solicitud-peritos').length) {
      const form = $('form.solicitud-peritos');

      //EXTRACT VALUES FROM: PAIS, PROVINCIA, MUNICIPIO
      form.find('select[name=IdPais]').on('change', function() {
        getProvincias(this.value);
      });
      form.find('select[name=IdProvincia]').on('change', function() {
        getMunicipios(this.value);
      });

      //Submit form
      form.submit(function(e) {
        e.preventDefault();

        //var $form = $(this);
        var form_data = form.serialize();

        //console.log(form_data);

        const originalSubmitText = form.find('button[type=submit] span').html();

        form.find('button[type=submit]').addClass('disabled sending');
        form.find('button[type=submit] span').html('Enviando...');
        form.find('button[type=submit]').attr('disabled', true);

        $.ajax({ 
          data: form_data,
          type: 'post',
          url: localize.ajaxurl,
          success: function(data) {
            //console.log(data);

            form.find('.msg').css('display', 'block');
            if(data.success && data.data.result == 'ok'){
              form.trigger('reset');
              form.find('.msg p').addClass('success').html(data.data.message);
            }

          },
          error: function(data) {
            console.log(data);
            form.find('.msg').css('display', 'block');
            form.find('.msg p').addClass('error').html(data.responseJSON.data.message);
          },
          complete: function() {
            form.find('button[type=submit]').removeClass('disabled sending');
            form.find('button[type=submit] span').html(originalSubmitText);
            form.find('button[type=submit]').attr('disabled', false);
            setTimeout(function(){
                form.find('.msg').css('display', 'none');
                form.find('.msg p').removeClass('success').removeClass('error').html('');
            }, 10000);
          }
        });
      });
    }

    /**
     * FORM SOLICITUD
     */
    if($('form.form-solicitud').length) {
      const form = $('form.form-solicitud');

      //EXTRACT VALUES FROM: PAIS, PROVINCIA, MUNICIPIO
      form.find('select[name=IdPais]').on('change', function() {
        var selectedText = $(this).find('option:selected').text();
        $('form.form-solicitud input[name=Pais]').val(selectedText);
        getProvincias(this.value);
      });
      form.find('select[name=IdProvincia]').on('change', function() {
        var selectedText = $(this).find('option:selected').text();
        $('form.form-solicitud input[name=Provincia]').val(selectedText);
        getMunicipios(this.value);
      });
      form.find('select[name=IdMunicipio]').on('change', function() {
        var selectedText = $(this).find('option:selected').text();
        $('form.form-solicitud input[name=Ciudad]').val(selectedText);
      });

      //SUBMIT FORM
      form.submit(function(e) {
        e.preventDefault();

        //var $form = $(this);
        var form_data = form.serialize();

        //console.log(form_data);

        const originalSubmitText = form.find('button[type=submit] span').html();

        form.find('button[type=submit]').addClass('disabled sending');
        form.find('button[type=submit] span').html('Enviando...');
        form.find('button[type=submit]').attr('disabled', true);

        $.ajax({ 
          data: form_data,
          type: 'post',
          url: localize.ajaxurl,
          success: function(data) {
            //console.log(data);

            form.find('.msg').css('display', 'block');
            if(data.success && data.data.result == 'ok'){
              form.trigger('reset');
              form.find('.msg p').addClass('success').html(data.data.message);
            }else {
              form.find('.msg p').addClass('error').html(data.data.message);
            }

          },
          error: function(data) {
            console.log(data);
            form.find('.msg').css('display', 'block');
            form.find('.msg p').addClass('error').html('No hemos podido enviar la solicitud, por favor, inténtelo de nuevo más tarde.');
          },
          complete: function() {
            form.find('button[type=submit]').removeClass('disabled sending');
            form.find('button[type=submit] span').html(originalSubmitText);
            form.find('button[type=submit]').attr('disabled', false);
            setTimeout(function(){
              form.find('.msg').css('display', 'none');
              form.find('.msg p').removeClass('success').removeClass('error').html('');
            }, 10000);
          }
        });
      });
    }

    /**
     * FORM ALTA USUARIO REGISTRADO
     */
    if($('form.alta-usuario-registrado').length) {
      const form = $('form.alta-usuario-registrado');

      //SUBMIT FORM
      form.submit(function(e) {
        e.preventDefault();

        //var $form = $(this);
        var form_data = form.serialize();

        //console.log(form_data);

        const originalSubmitText = form.find('button[type=submit] span').html();

        form.find('button[type=submit]').addClass('disabled sending');
        form.find('button[type=submit] span').html('Enviando...');
        form.find('button[type=submit]').attr('disabled', true);

        $.ajax({ 
          data: form_data,
          type: 'post',
          url: localize.ajaxurl,
          success: function(data) {
            //console.log(data);

            form.find('.msg').css('display', 'block');
            if(data.success && data.data.result == 'ok'){
              form.trigger('reset');
              form.find('.msg p').addClass('success').html(data.data.message);
            }else {
              form.find('.msg p').addClass('error').html(data.data.message);
            }

          },
          error: function(data) {
            console.log(data);
            form.find('.msg').css('display', 'block');
            form.find('.msg p').addClass('error').html('No hemos podido enviar la solicitud, por favor, inténtelo de nuevo más tarde.');
          },
          complete: function() {
            form.find('button[type=submit]').removeClass('disabled sending');
            form.find('button[type=submit] span').html(originalSubmitText);
            form.find('button[type=submit]').attr('disabled', false);
            setTimeout(function(){
              form.find('.msg').css('display', 'none');
              form.find('.msg p').removeClass('success').removeClass('error').html('');
            }, 10000);
          }
        });
        
      });
    }

    /**
     * LOADMORE POSTS
     */
    $('.coiimtheme_loadmore').click(function(event){
      event.preventDefault();

      //console.log('LOADMORE CLICK');
      //console.log('Localize:', localize);
    
      var button = $(this);
      var buttton_html = button.html();

      var data = {
        'action': 'loadmore',
        'query': localize.posts,
        'page' : localize.current_page,
        'ajax_nonce': localize.ajax_nonce
      };
     
      $.ajax({
        url : localize.ajaxurl, // AJAX handler
        data : data,
        type : 'POST',
        beforeSend : function ( xhr ) {
          button.html('Cargando...');
        },
        success : function( data ){
          if( data ) { 
            $('#contenido_articulos').append(data); // insert new posts
            localize.current_page++;

            button.html(buttton_html);
    
            if ( localize.current_page == localize.max_page ) { 
              button.remove(); // if last page, remove the button
            }
          } else {
            button.remove(); // if no data, remove the button
          }
        }
      }).done(function(data) {
        //console.log(data);
      }).fail(function(error) {
        console.error(error)
      });
    });

    /**
     * FILTER BY TAXONOMY
     */
    if($('#taxonomyFilter .filter-actualidad__filtro-checkboxes').length) {
      $('#taxonomyFilter .filter-actualidad__filtro-checkboxes').find('input[type=checkbox]').on('change', function() {
        taxonomyFilter();
      });
    }
    if($('#taxonomyFilter').length) {
      $('#filterReset').on('click', function(){
        $('#taxonomyFilter').find('input[type=checkbox]').prop('checked', false);
        taxonomyFilter();
      });
    }

    /**
     * FILTER COLLAPSIBLE
     */
    /*
      if($('#taxonomyFilter .header-collapse').length){
        $('#taxonomyFilter .header-collapse').click(function(event){
          //console.log($(this));
          $(this).toggleClass('on').toggleClass('off');
          $(this).find('span i').toggleClass('icon-arrow-up').toggleClass('icon-arrow-down');
          $(this).parent('.collapsible').find('.content-collapse').toggleClass('on').toggleClass('off');
        });
      }
    */

    /**
     * FILTER BY "SEDES"
     */
    if($('#filterSedes').length) {
      $('#filterSedes').find('select[id=sedes]').on('change', function() {
        sedesFilter();
      });
    }

    /**
     * TABS
     */
    if($('#tabs').length){
      //console.log('TABS READY!');
      var hash = window.location.hash;
      hash = hash.replace('#tab-','');
      //console.log('hash: ', hash);
      
      if(hash != ''){
        if ($('#tabs .tabs__tab[data-tabid=' + hash + ']').length > 0) {
          scrollToAnchor('#tabs');

          if(!$('#tabs .tabs__tab[data-tabid=' + hash + ']').hasClass('active')){
            $('#tabs .tabs__tab').removeClass('active');
            $('#tabs .tabs__tab[data-tabid=' + hash + ']').addClass('active');
            
            var tabId = $('#tabs .tabs__tab[data-tabid=' + hash + ']').data('tabid');
            $('.tabcontent').css('display', 'none');
            $('#'+tabId).fadeIn(800);
          }
        }
      }

      $('#tabs .tabs__tab').on('click', function(event){
        event.preventDefault();

        //console.log('Tab Clicked!');

        if(!$(this).hasClass('active')){
          $('#tabs .tabs__tab').removeClass('active');
          $(this).addClass('active');
          
          var tabId = $(this).data('tabid');
          $('.tabcontent').css('display', 'none');
          $('#'+tabId).fadeIn(800);

          //scrollToAnchor('#tabs');
          location.hash = 'tab-'+tabId;
        }

      });
    }

    /**
     * JQUERY FILTER BY "SEDES"
     */
    if($('#filterSedesInline').length){
      $('#filterSedesInline').find('select[id=sedes]').on('change', function() {
        var selected = $('#filterSedesInline select[id=sedes] option').filter(':selected').val();
        //console.log(selected);
        if(selected == ''){
          $('.cards__card').fadeIn(800);
        }else {
          $('.cards__card').css('display', 'none');
          $('.cards__card .cards__card__txt p:contains(' + selected + ')').parents('.cards__card').fadeIn(800);
        }
      });
    }
    if($('.btn-mostrarElem').length){
      $('.btn-mostrarElem').on('click', function(){
        mostrarElem($(this).data('mostrar'));
      });
    }

    /**
     * SLIDER (ESPACIOS)
     * Configuration.
     */
    if($('.slider.slider--espacios').length) {
      $('.slider.slider--espacios').slick({
        arrows: true,
        dots: true,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnFocus: false,
        pauseOnHover: true,
        infinite: true,
        speed: 1000,
        fade: true,
        cssEase: 'ease-in-out',
        //nextArrow: '.slider__btn--next',
        //prevArrow: '.slider__btn--prev'
      });
    }

    /**
     * SLIDER (COWORKING)
     * Configuration.
     */
    if($('.slider.slider--coworking').length) {
      $('.slider.slider--coworking').slick({
        arrows: true,
        dots: true,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnFocus: false,
        pauseOnHover: true,
        infinite: true,
        speed: 1000,
        fade: true,
        cssEase: 'ease-in-out',
        //nextArrow: '.slider__btn--next',
        //prevArrow: '.slider__btn--prev'
      });
    }

    /**
     * SLIDER (wp-block-gallery)
     * Configuration.
     */
    if($('.slider.slider--gallery').length) {
      $('.slider.slider--gallery').slick({
        arrows: true,
        dots: true,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnFocus: false,
        pauseOnHover: true,
        infinite: true,
        speed: 1000,
        fade: true,
        cssEase: 'ease-in-out',
        //nextArrow: '.slider__btn--next',
        //prevArrow: '.slider__btn--prev'
      });
    }

    /**
     * MODAL
     * Open
     */
    $('body').on('click', '.open-modal', function(e) {
      e.preventDefault();
      var modal = $(this).attr('href');

      $(modal).addClass('fadeScale');
      $('body').addClass('modal-open');

      //$('.page-overlay').css('display', 'block');
    });

    /**
     * MODAL
     * Close
     */
    $('.modal .close').click(function(e) {
      e.preventDefault();
      $(this).parents('.modal').removeClass('fadeScale');
      $('body').removeClass('modal-open');
      //$('.page-overlay').css('display', 'none');
    });
    $('.modal').click(function(event) {
      var modalContainer = $('.modal-content');

      if (!modalContainer.is(event.target) && !modalContainer.has(event.target).length && $('body').hasClass('modal-open')){
        $('.modal').removeClass('fadeScale');
        $('body').removeClass('modal-open');
      }
    });

    /**
     * FORM SOLICITUD ÁRBITRO
     */
    if($('form.solicitud-arbitro').length) {
      //console.log('SOLICITUD ÁRBITRO READY');

      const form = $('form.solicitud-arbitro');

      //EXTRACT VALUES FROM: PAIS, PROVINCIA, MUNICIPIO
      form.find('select[name=IdPais]').on('change', function() {
        var selectedText = $(this).find('option:selected').text();
        $('form.solicitud-arbitro input[name=Pais]').val(selectedText);
        getProvincias(this.value);
      });
      form.find('select[name=IdProvincia]').on('change', function() {
        var selectedText = $(this).find('option:selected').text();
        $('form.solicitud-arbitro input[name=Provincia]').val(selectedText);
        getMunicipios(this.value);
      });
      form.find('select[name=IdMunicipio]').on('change', function() {
        var selectedText = $(this).find('option:selected').text();
        $('form.solicitud-arbitro input[name=Ciudad]').val(selectedText);
      });

      //SUBMIT FORM
      form.submit(function(e) {
        e.preventDefault();

        //var $form = $(this);
        var form_data = form.serialize();

        //console.log(form_data);

        const originalSubmitText = form.find('button[type=submit] span').html();

        form.find('button[type=submit]').addClass('disabled sending');
        form.find('button[type=submit] span').html('Enviando...');
        form.find('button[type=submit]').attr('disabled', true);

        $.ajax({ 
          data: form_data,
          type: 'post',
          url: localize.ajaxurl,
          success: function(data) {
            //console.log(data);

            form.find('.msg').css('display', 'block');
            if(data.success && data.data.result == 'ok'){
              form.trigger('reset');
              form.find('.msg p').addClass('success').html(data.data.message);
            }else {
              form.find('.msg p').addClass('error').html(data.data.message);
            }

          },
          error: function(data) {
            console.log(data);
            form.find('.msg').css('display', 'block');
            form.find('.msg p').addClass('error').html('No hemos podido enviar la solicitud, por favor, inténtelo de nuevo más tarde.');
          },
          complete: function() {
            form.find('button[type=submit]').removeClass('disabled sending');
            form.find('button[type=submit] span').html(originalSubmitText);
            form.find('button[type=submit]').attr('disabled', false);
            setTimeout(function(){
              form.find('.msg').css('display', 'none');
              form.find('.msg p').removeClass('success').removeClass('error').html('');
            }, 10000);
          }
        });
      });
    }

    /**
     * QUEJAS Y RECLAMACIONES
     */
    if($('form.form-quejas-reclamaciones').length) {
      //console.log('QUEJAS Y RECLAMACIONES READY');

      const form = $('form.form-quejas-reclamaciones');
      
      //SUBMIT FORM
      form.submit(function(e) {
        e.preventDefault();

        //var $form = $(this);
        var form_data = form.serialize();

        //console.log(form_data);

        const originalSubmitText = form.find('button[type=submit] span').html();

        form.find('button[type=submit]').addClass('disabled sending');
        form.find('button[type=submit] span').html('Enviando...');
        form.find('button[type=submit]').prop('disabled', true);

        $.ajax({ 
          data: form_data,
          type: 'post',
          url: localize.ajaxurl,
          success: function(data) {
            //console.log(data);

            form.find('.msg').css('display', 'block');
            if(data.success && data.data.result == 'ok'){
              form.trigger('reset');
              form.find('.msg p').addClass('success').html(data.data.message);
            }else {
              form.find('.msg p').addClass('error').html(data.data.message);
            }

          },
          error: function(data) {
            console.log(data);
            form.find('.msg').css('display', 'block');
            form.find('.msg p').addClass('error').html('No hemos podido enviar la solicitud, por favor, inténtelo de nuevo más tarde.');
          },
          complete: function() {
            form.find('button[type=submit]').removeClass('disabled sending');
            form.find('button[type=submit] span').html(originalSubmitText);
            form.find('button[type=submit]').prop('disabled', false);

            setTimeout(function(){
              form.find('.msg').css('display', 'none');
              form.find('.msg p').removeClass('success').removeClass('error').html('');
            }, 10000);
          }
        });
      });
    }

    /**
     * FORM LISTADO COLEGIADOS
     */
    if($('form.form-listado-colegiados').length) {
      //console.log('LISTADO COLEGIADOS READY');
      const form = $('form.form-listado-colegiados');

      form.submit(function(event, isPagination) {
        event.preventDefault();

        if(!isPagination){
          form.find('input[name=pagina]').val(0);
        }

        //var $form = $(this);
        var form_data = form.serialize();
        //console.log(form_data);

        if((form.find('input[name=NumColegiado]').val() == '' && form.find('input[name=Nombre]').val() == '') || (form.find('input[name=NumColegiado]').val() == '' && form.find('input[name=Apellidos]').val() == '') || (form.find('input[name=Nombre]').val() == '' && form.find('input[name=Apellidos]').val() == '')){
          form.find('.msg').css('display', 'block');
          form.find('input[name=NumColegiado]').addClass('invalid');
          form.find('input[name=Nombre]').addClass('invalid');
          form.find('input[name=Apellidos]').addClass('invalid');
          form.find('.msg p').addClass('error').html('Debe rellenar al menos dos de los campos de la búsqueda.');
        }else{
          $('.datos-colegiado').fadeOut(100);
          $('.datos-colegiado p span').html('');
          form.find('.msg').css('display', 'none');
          form.find('.msg p').removeClass('success').removeClass('error').html('');
          form.find('input[name=Nombre]').removeClass('invalid');
          form.find('input[name=Apellidos]').removeClass('invalid');
          form.find('input[name=NumColegiado]').removeClass('invalid');

          const originalSubmitText = form.find('button[type=submit] span').html();

          form.find('button[type=submit]').addClass('disabled sending');
          form.find('button[type=submit] span').html('Buscando...');
          form.find('button[type=submit]').prop('disabled', true);

          $.ajax({ 
            data: form_data,
            type: 'post',
            url: localize.ajaxurl,
            success: function(data) {
              //console.log(data);

              if(data.success && data.data.result == 'ok'){
                //form.trigger('reset');

                $('.datos-colegiado .listado-colegiados').html(data.data.html);
                $('.datos-colegiado .paginacion').html(data.data.html_paginacion);
                $('.datos-colegiado').fadeIn(600);
              }
            },
            error: function(data) {
              console.log(data);
              form.find('.msg').css('display', 'block');
              form.find('.msg p').addClass('error').html(data.responseJSON.data.message);
            },
            complete: function() {
              form.find('button[type=submit]').removeClass('disabled sending');
              form.find('button[type=submit] span').html(originalSubmitText);
              form.find('button[type=submit]').prop('disabled', false);
              setTimeout(function(){
                form.find('.msg').css('display', 'none');
                form.find('.msg p').removeClass('success').removeClass('error').html('');
              }, 10000);
            }
          });
        }
      });

      $(document).on('click', '.paginacion .btn', function(event){
        event.preventDefault();

        //console.log('CAMBIAR PÁGINA');

        const pag = $(this).data('pag');
        //console.log('IR PÁGINA:', pag);
        form.find('input[name=pagina]').val(pag);
        //form.find('button[type=submit]').trigger('click');
        form.trigger('submit', true);
      });

      $(document).on('click', '.listado-colegiados .item .ver-mas', function(event){
        event.preventDefault();
        //console.log('VER MÁS');

        if($(this).parents('.item').hasClass('on')){
          $(this).parents('.item').removeClass('on').addClass('off');
          $(this).find('a').html('<i class=" icon-arrow-down"></i>');
        }else{
          $(this).parents('.item').removeClass('off').addClass('on');
          $(this).find('a').html('<i class=" icon-arrow-up"></i>');
        }
      });
    }

    /**
     * COLLAPSIBLE
     */
    if($('.collapsible').length){
      $(document).on('click', '.collapsible .header-collapse', function(event){
        //console.log('Collapsible: ', $(this));
        $(this).toggleClass('on').toggleClass('off');
        $(this).find('span i').toggleClass('icon-arrow-up').toggleClass('icon-arrow-down');
        $(this).parent('.collapsible').find('.content-collapse').toggleClass('on').toggleClass('off');
      });
    }

    /**
     * CERTIFICADORES ENERGÉTICOS
     * LISTADO
     */
    if($('#listado-certificadores').length) {

      //LOADMORE BUTTON
      $('.btn.loadmore').on('click', function(event){
        event.preventDefault();

        const itemsToShow = $('#listado-certificadores').data('itemstoshow');
        var displayedItems = $('#listado-certificadores').find('.item:visible').size();
        const totalItems = $('#listado-certificadores').find('.item').size();

        displayedItems = (displayedItems + itemsToShow <= totalItems) ? displayedItems + itemsToShow : totalItems;
        $('#listado-certificadores').find('.item').slice(0, displayedItems).show();
        if(displayedItems >= totalItems){
          $('.btn.loadmore').hide();
        }
      });

      //SEARCH BY NOMBRE
      $('#certificadores input[name=busqueda]').on('keyup', function() {
        var value = $(this).val().toLowerCase();
        var sede = $('#certificadores select[name=sedes]').val().toLowerCase();

        if(value){
          $('#listado-certificadores .item .nombre').filter(function() {
            $(this).parent('.item').toggle($(this).text().toLowerCase().indexOf(value) > -1);
            if(sede){
              if($(this).parent('.item').find('.sede').text().toLowerCase().indexOf(sede) == -1){
                $(this).parent('.item').hide();
              }
            }
          });
          $('.btn.loadmore').hide();
        }else{
          if(sede){
            $('#certificadores select[name=sedes]').trigger('change');
          }else{
            //displayedItems = itemsToShow;
            $('#listado-certificadores .item').hide();
            $('#listado-certificadores .item:lt(' + displayedItems + ')').show();
            if(displayedItems < totalItems){
              $('.btn.loadmore').show();
            }
          }
        }
      });

      //FILTER BY SEDE
      $('#certificadores select[name=sedes]').on('change', function() {
        var value = $(this).val().toLowerCase();
        var nombre = $('#certificadores input[name=busqueda]').val();

        if(value){
          $('#listado-certificadores .item .sede').filter(function() {
            $(this).parent('.item').toggle($(this).text().toLowerCase().indexOf(value) > -1);
            if(nombre){
              if($(this).parent('.item').find('.nombre').text().toLowerCase().indexOf(nombre) == -1){
                $(this).parent('.item').hide();
              }
            }
          });
          $('.btn.loadmore').hide();
        }else{
          if(nombre){
            $('#certificadores input[name=busqueda]').trigger('keyup');
          }else{
            //displayedItems = itemsToShow;
            $('#listado-certificadores .item').hide();
            $('#listado-certificadores .item:lt(' + displayedItems + ')').show();
            if(displayedItems < totalItems){
              $('.btn.loadmore').show();
            }
          }
        }
      });
    }

    /**
     * FORMACIÓN
     * LISTADOS
     */
    if($('#listado-formaciones').length) {

      filterFormacion();

      //FILTER BY "CATEGORIES"
      $('#taxonomyFilterFormacion input[type=checkbox]').on('click', function() {
        filterFormacion();
      });
      
      //FILTER BY SEDE
      $('#filterSedesFormacion select[name=sedes]').on('change', function() {
        filterFormacion();
      });
    }
    
    if($('#taxonomyFilterFormacion').length) {
      //RESET BUTTON
      $('#filterReset').on('click', function(){
        $('#taxonomyFilterFormacion').find('input[type=checkbox]').prop('checked', false);
        filterFormacion();
      });

      //HIDE EMPTY "CATEGORIES"
      $('.collapsible .content-collapse').each(function(){
        if(!$(this).find('.filter-actualidad__filtro-checkboxes').length){
          $(this).parent('.collapsible').hide();
        }
      });
    }

    /**
     * FORMACIÓN
     * MOSTRAR/OCULTAR INSCRIPCIÓN
     */
    if($('.single-formacion .evocard_box.inscripcion').length) {
      $('.evcal_btn.inscripcion').on('click', function(){
        //console.log('MOSTRAR/OCULTAR INSCRIPCIÓN');
        $('.evocard_box.inscripcion').toggle();
        getFormacionInscripcionFrom();
      });
    }

    /**
     * FORMACIÓN
     * MOSTRAR/OCULTAR LOGIN/REGISTRO
     */
    if($('.single-formacion .evocard_box.login').length) {
      $('.evcal_btn.login').on('click', function(){
        console.log('MOSTRAR/OCULTAR LOGIN');
        $('.page-overlay').toggle();
        $('.evocard_box.login').toggle();
        scrollToAnchor('.forms-formacion', totalHeaderHeight);
      });
    }

    /**
     * FORMACIÓN
     * FORM INSCRIPCIÓN
     */
    function formacionInscripcionLoaded(){
      if($('form.formacion-inscripcion').length) {
        console.log('FORMACIÓN INSCRIPCIÓN READY');

        const form = $('form.formacion-inscripcion');

        //EXTRACT VALUES FROM: PAIS, PROVINCIA, MUNICIPIO
        form.find('select.api-IdPais').on('change', function() {
          //console.log('GET PROVINCIAS 2');
          var fieldToUpdate = $(this).attr('data-fieldToUpdate');
          getProvincias2(this.value, fieldToUpdate);
        });

        form.find('select.api-IdProvincia').on('change', function() {
          //console.log('GET MUNICIPIOS 2');
          var fieldToUpdate = $(this).attr('data-fieldToUpdate');
          getMunicipios2(this.value, fieldToUpdate);
        });

        //***DATOS PERSONALES***

        //VALIDATE NIF/NIE
        form.find('input[name=nif]').on('change', function() {
          //console.log('VALIDAR NIF');
          var nif_nie = $(this).val();
          if(!validarNIF_NIE(nif_nie)){
            $(this).addClass('invalid');
            $(this).get(0).setCustomValidity('El NIF no es válido.');
          }else{
            $(this).removeClass('invalid');
            $(this).get(0).setCustomValidity('');
          }
        });

        //****GET DESCRIPTION VALUES FROM SELECTS****
        form.find('select[name=IdMunicipioParticular]').on('change', function() {
          var selectedText = $(this).find('option:selected').text();
          $('input[name=DesIdMunicipio]').val(selectedText);
        });

        form.find('select[name=profesionales_cargo]').on('change', function() {
          var selectedText = $(this).find('option:selected').text();
          $('input[name=DesCargo]').val(selectedText);
        });

        form.find('select[name=profesionales_sector]').on('change', function() {
          var selectedText = $(this).find('option:selected').text();
          $('input[name=DesSector]').val(selectedText);
        });

        //***DATOS FACTURACIÓN***

        //****GET DESCRIPTION VALUES FROM SELECTS****
        form.find('select[name=IdMunicipioFactura]').on('change', function() {
          var selectedText = $(this).find('option:selected').text();
          $('input[name=DesIdMunicipioFacturacion]').val(selectedText);
        });

        //COPY PERSONAL DATA
        $('.copiar-datos').on('click', function(event){
          event.preventDefault();

          //console.log('COPY DATA BTN CLICKED');

          $('.facturacion_contenido').addClass('loading');

          var nombreCopy = form.find('input[name=nombre]').val();
          var apellidosCopy = form.find('input[name=apellidos]').val();
          var nifCopy = form.find('input[name=nif]').val();
          var emailCopy = form.find('input[name=email]').val();
          var direccionCopy = form.find('input[name=direccion]').val();
          var cpCopy = form.find('input[name=cp]').val();

          form.find('input[name=factura_titular]').val(nombreCopy +' '+ apellidosCopy).removeClass('invalid');
          form.find('select[name=factura_tipo_identificacion]').val('NIF').removeClass('invalid');
          form.find('input[name=factura_num_identificacion]').val(nifCopy).removeClass('invalid');
          form.find('input[name=factura_contacto]').val(emailCopy).removeClass('invalid');
          form.find('input[name=factura_direccion]').val(direccionCopy).removeClass('invalid');
          form.find('input[name=factura_cp]').val(cpCopy).removeClass('invalid');

          var paisCopy = form.find('#IdPaisParticular').val();
          //console.log('paisCopy: ', paisCopy);

          form.find('select[name=IdPaisFactura]').one('change', function(event) {
            var provinciaCopy = form.find('#IdProvinciaParticular').val();
            //console.log('provinciaCopy:', provinciaCopy);

            setTimeout(function() {
              form.find('select[name=IdProvinciaFactura]').val(provinciaCopy).removeClass('invalid');
              form.find('select[name=IdProvinciaFactura]').trigger('change');
            }, 5000);
          });

          form.find('select[name=IdProvinciaFactura]').one('change', function(event) {
            var municipioCopy = form.find('#IdMunicipioParticular').val();
            //console.log('municipioCopy:', municipioCopy);

            setTimeout(function() {
              form.find('select[name=IdMunicipioFactura]').val(municipioCopy).removeClass('invalid');
              form.find('select[name=IdMunicipioFactura]').trigger('change');
              $('.facturacion_contenido').removeClass('loading');
            }, 5000);
          });

          if(paisCopy){
            form.find('select[name=IdPaisFactura]').val(paisCopy).trigger('change');
          }else{
            $('.facturacion_contenido').removeClass('loading');
          }
        });

        //SUBMIT FORM
        form.submit(function(event) {
          event.preventDefault();

          //var $form = $(this);
          var form_data = form.serialize();
          //console.log(form_data);

          const originalSubmitText = form.find('button[type=submit] span').html();

          form.find('button[type=submit]').addClass('disabled sending');
          form.find('button[type=submit] span').html('Enviando...');
          form.find('button[type=submit]').prop('disabled', true);

          $.ajax({ 
            data: form_data,
            type: 'POST',
            url: localize.ajaxurl,
            contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
            cache: false,
            processData:false,
            success: function(data) {
              console.log(data);

              form.find('.msg').css('display', 'block');
              if(data.success && data.data.result == 'ok'){
                form.trigger('reset');

                form.find('.msg p').addClass('success').html(data.data.message);
              }else {
                form.find('.msg p').addClass('error').html(data.data.message);
              }

            },
            error: function(error) {
              console.log(error);
              form.find('.msg').css('display', 'block');
              form.find('.msg p').addClass('error').html('No hemos podido enviar la solicitud, por favor, inténtelo de nuevo más tarde.');
            },
            complete: function() {
              form.find('button[type=submit]').removeClass('disabled sending');
              form.find('button[type=submit] span').html(originalSubmitText);
              form.find('button[type=submit]').prop('disabled', false);

              setTimeout(function(){
                form.find('.msg').css('display', 'none');
                form.find('.msg p').removeClass('success').removeClass('error').html('');
              }, 20000);
            }
          });

        });
      }
    }

    /**
     * FORMACIÓN
     * GET INSCRIPTION FORM
     */
    function getFormacionInscripcionFrom() {
      console.log('LOAD FORM INSCRIPTION');

      var nonce = localize.ajax_nonce;
      var formData = { 
        'action': 'formacion_inscripcion_form',
        'security': nonce,
        'jsonData': jsonData
      }

      $.ajax({
        url: localize.ajaxurl,
        type: 'POST',
        dataType: 'html',
        data: formData,
        beforeSend : function ( xhr ) {
          $('.page-overlay, .evocard_box.login').hide();
          $('.evocard_box.inscripcion').show();
          $('.evocard_box.inscripcion .form_content').html(htmlSpinerLoading);
        },
        success: function(data){
          //console.log(data);

          $('.evocard_box.inscripcion .form_content').html(data);
          $('.evocard_box.inscripcion').show(100, function(){
            formacionInscripcionLoaded();
            $('.evotx_addtocart_button .evcal_btn').removeClass('login').addClass('inscripcion').attr('disabled', true);
            scrollToAnchor('.forms-formacion', totalHeaderHeight);
          });
        },
        error: function(data){
          console.error(data);
        },
        complete: function(data){
          //console.log('FORM INSCRIPTION LOADED!');
        }
      });
    }

    /**
     * FORM SOLICITUD ALTA COLEGIADO
     */
    if($('form.solicitud-alta-colegiado').length) {
      //console.log('SOLICITUD ALTA COLEGIADO READY');

      const form_class = 'form.solicitud-alta-colegiado';
      const form = $(form_class);

      //VALIDATE NIF/NIE
      form.find('input[name=NIF]').on('change', function() {
        var nif_nie = $(this).val();
        if(!validarNIF_NIE(nif_nie)){
          $(this).addClass('invalid');
          $(this).get(0).setCustomValidity('El NIF no es válido.');
        }else{
          $(this).removeClass('invalid');
          $(this).get(0).setCustomValidity('');
        }
      });

      //VALIDATE BIRTHDATE
      form.find('input.isValidDateDay, input.isValidDateMonth, input.isValidDateYear').on('change', function() {
        validateInputDate(form);
      });

      //EXTRACT VALUES FROM: PAIS, PROVINCIA, MUNICIPIO
      form.find('select.api-IdPais').on('change', function() {
        var fieldToUpdate = $(this).attr('data-fieldToUpdate');
        getProvincias2(this.value, fieldToUpdate);
      });
      form.find('select.api-IdProvincia').on('change', function() {
        var fieldToUpdate = $(this).attr('data-fieldToUpdate');
        getMunicipios2(this.value, fieldToUpdate);
      });

      /**
       * SELECT "TIPO COLEGIACIÓN"
       */
      form.find('input[name=colegiacion]').on('change', function() {
        var selected = $(this).val();
        //console.log('SELECTED: ', selected);

        if(selected == 'adscrito'){
          form.find('label.aiim').addClass('disabled');
          form.find('input[name=AIIM]').prop('checked', false);
          form.find('input[name=AIIM]').prop('disabled', true);
        }else{
          form.find('label.aiim').removeClass('disabled');
          form.find('input[name=AIIM]').prop('disabled', false);
        }
      });

      //SELECT ESCUELA
      /*
      form.find('.datos-academicos').on('change', 'select.api-IdEscuela', function() {
        //console.log('SELECT ESCUELA CHANGED', $(this).val());

        var fieldToUpdate = $(this).attr('data-fieldToUpdate');
        var especialidadNum = $(this).parents('.carrera').data('especialidadnum');

        //getEspecialidadesByIdEscuela(this, fieldToUpdate, especialidadNum);
        $('input[name=DesEspecialidad]').val('');

        if($(this).val()){
          $('span[id^=select2-IdEscuela]').addClass('active');
        }else{
          $('span[id^=select2-IdEscuela]').removeClass('active');
        }
      });
      */

      //SELECT ESPECIALIDAD
      form.find('.datos-academicos').on('change', 'select.api-IdEspecialidad', function() {
        $('input[name=DesEspecialidad]').val($(this).find('option:selected').text());

        /*
        if($(this).val()){
          $('span[id^=select2-IdEspecialidad]').addClass('active');
        }else{
          $('span[id^=select2-IdEspecialidad]').removeClass('active');
        }
        */
      });

      //ADD ESPECIALIDAD
      /*
      form.find('.btn.addEspecialidad').on('click', function(){
        //console.log('Add especialidad');

        var especialidadHTML = $('.carrera').prop('outerHTML');

        //console.log(especialidadHTML);

        form.find('.datos-academicos').append(especialidadHTML);
        
        var totalEspecialidades = $('.carrera').length;
        var counterEspecialidades = 0;
        $('.carrera').each(function( index ) {
          counterEspecialidades = counterEspecialidades + 1;
          //console.log('index: ', index);
          if(index > 0){
            $(this).addClass('added');
            $(this).attr('data-especialidadnum', index);
            if(!$(this).find('.btn.removeEspecialidad').length){
              $(this).append('<p><div class="btn btn-secondary removeEspecialidad" data-removeespecialidadnum="'+index+'"><i class="icon-close"></i> Eliminar especialidad</div></p>');
            }else{
              $(this).find('.btn.removeEspecialidad').attr('data-removeespecialidadnum', index);
            }

            if(counterEspecialidades == totalEspecialidades){
              $(this).find('select[name^=IdEspecialidad] option').not(':first').remove();
            }
          }
        });

      });
      */

      //REMOVE ESPECIALIDAD
      /*
      form.find('.datos-academicos').on('click', '.carrera.added .btn.removeEspecialidad', function(){
        //console.log('Remove especialidad');

        var especialidadNum = $(this).data('removeespecialidadnum');
        $('.carrera.added[data-especialidadnum=' + especialidadNum + ']').remove();
      });
      */

      //SELECT PROVINCIAS
      $(form_class+'.step-3').on('change', 'select[name=IdProvinciaProfesional]', function() {
        $('input[name=DesProvincia]').val($(this).find('option:selected').text());
      });

      //SELECT MUNICIPIO
      $(form_class+'.step-3').on('change', 'select[name=IdMunicipioProfesional]', function() {
        $('input[name=DesMunicipio]').val($(this).find('option:selected').text());
      });

      //SELECT AREAS FUNCIONALES
      $(form_class+'.step-3').on('change', 'select[name=AreasFuncionales]', function() {
        $('input[name=DesAreaFuncional]').val($(this).find('option:selected').text());
      });

      //SELECT AREAS FUNCIONALES
      $(form_class+'.step-3').on('change', 'select[name=GuposProfesionales]', function() {
        $('input[name=DesGrupoProfesional]').val($(this).find('option:selected').text());
      });
      
      //SELECT SECTOR PROFESIONAL
      $(form_class+'.step-3').on('change', 'select[name=IdSectorProfesional]', function() {
        $('input[name=DescSectorProfesional]').val($(this).find('option:selected').text());
      });

      //DOCUMENTS SENDING OPTION
      $(form_class+'.step-5').on('change', 'input[name=enviarMasTarde]', function() {
        if($(this).is(':checked')){
          $('.documentacion-si').addClass('hidden');
          $('.documentacion-no').removeClass('hidden');

          $(form_class+'.step-5').find('input[name=dni_pasaporte]').prop('required', false);
          $(form_class+'.step-5').find('input[name=domiciliacion_bancaria]').prop('required', false);
          $(form_class+'.step-5').find('input[name=titulo_universitario]').prop('required', false);
          $(form_class+'.step-5').find('input[name=ficha_solicitud]').prop('required', false);
        }else{
          $('.documentacion-no').addClass('hidden');
          $('.documentacion-si').removeClass('hidden');

          $(form_class+'.step-5').find('input[name=dni_pasaporte]').prop('required', true);
          $(form_class+'.step-5').find('input[name=domiciliacion_bancaria]').prop('required', true);
          $(form_class+'.step-5').find('input[name=titulo_universitario]').prop('required', true);
          $(form_class+'.step-5').find('input[name=ficha_solicitud]').prop('required', true);
        }
      });

      //PREVIOUS BUTTON
      form.find('.btn-anterior').on('click', function(event) {
        event.preventDefault();
        var step = $(this).data('step');
        showFormSteps(step);
      });

      //DOWNLOAD FICHA ALTA
      form.find('.ficha-alta-colegiacion').on('click', function(event) {
        //console.log('DOWNLOAD FICHA ALTA COLEGIACIÓN');

        event.preventDefault();

        if($(this).hasClass('enabled')){

          var formDataStep1 = $(form_class+'.step-1').serialize();
          var formDataStep2 = $(form_class+'.step-2').serialize();
          var formDataStep3 = $(form_class+'.step-3').serialize();
          var formDataStep4 = $(form_class+'.step-4').serialize();

          var allDataForms = 'action=download_ficha_alta_colegiado&' + formDataStep1 + '&' + formDataStep2 + '&' + formDataStep3 + '&' + formDataStep4;
          
          //console.log('ALL SETPS FORM DATA: ', allDataForms);
          
          $(this).find('span').text('generando PDF...').removeClass('enabled').addClass('disabled');

          $.ajax({
            data: allDataForms,
            type: 'POST',
            url: localize.ajaxurl,
            xhrFields: { responseType: 'blob' },
            success: function(data) {
              //console.log(data);

              var blob = new Blob([data]);
              var link = document.createElement('a');
              link.href = window.URL.createObjectURL(blob);
              link.download = "Ficha-solicitud-alta-Colegiado.pdf";
              link.click();
            },
            error: function(data) {
              console.log(data);
              form.find('.msg').css('display', 'block');
              form.find('.msg p').addClass('error').html(data.responseJSON.data.message);
            },
            complete: function(){
              form.find('.ficha-alta-colegiacion span').text('descargar').removeClass('disabled').addClass('enabled');
            },
            done: function(){
              form.find('.ficha-alta-colegiacion span').text('descargar').removeClass('disabled').addClass('enabled');
            }
          });
        }

      });

      //VALIDATE IBAN (CONTROL DIGIT)
      form.find('input[name=CodigoIBAN]').on('change', function() {
        var iban = $(this).val();

        console.log('VALIDATE IBAN: ', iban);

        if(!validarDigitoControl(iban)){
          console.log('IBAN KO');
          $(this).addClass('invalid');
          $(this).get(0).setCustomValidity('El IBAN introducido no es correcto.');
        }else{
          console.log('IBAN OK');
          $(this).removeClass('invalid');
          $(this).get(0).setCustomValidity('');
        }
      });

      //SUBMIT FORM
      form.submit(function(event) {
        event.preventDefault();

        var step = $(this).data('step');
        //console.log('Step: ', step);
        var goToSetp = step + 1;

        //var $form = $(this);
        /*
        var form_data = form.serialize();
        console.log(form_data);
        */

        if(step != 5){
          //console.log('GO TO STEP: ', goToSetp);
          showFormSteps(goToSetp);
        }else{
          var formDataStep1 = $(form_class+'.step-1').serialize();
          var formDataStep2 = $(form_class+'.step-2').serialize();
          var formDataStep3 = $(form_class+'.step-3').serialize();
          var formDataStep4 = $(form_class+'.step-4').serialize();
          //var formDataStep5 = $(form_class+'.step-5').serialize();

          const allDataForms = formDataStep1 + '&' + formDataStep2 + '&' + formDataStep3 + '&' + formDataStep4;
          //const allDataForms = Object.fromEntries(new URLSearchParams(allDataFormsString));
          form.find('input[name=formsdata]').val(allDataForms);
          
          //console.log('ALL SETPS FORM DATA: ', allDataForms);
          
          const originalSubmitText = form.find('.btn-enviar span').html();

          form.find('.btn-enviar').addClass('disabled sending');
          form.find('.btn-enviar span').html('Enviando...');
          form.find('.btn-enviar').attr('disabled', true);

          $.ajax({ 
            data: new FormData(this),
            type: 'POST',
            url: localize.ajaxurl,
            contentType: false,
            cache: false,
            processData:false,
            success: function(data) {
              console.log(data);

              form.find('.msg').css('display', 'block');
              if(data.success && data.data.result == 'ok'){
                form.trigger('reset');
                $('.hide-on-success').hide();

                form.find('.msg p').removeClass('error').addClass('success').html(data.data.message);
              }

            },
            error: function(data) {
              console.log(data);
              $('.hide-on-success').hide();
              form.find('.msg').css('display', 'block');
              form.find('.msg p').addClass('error').html(data.responseJSON.data.message);
            },
            complete: function() {
              form.find('.btn-enviar').removeClass('disabled sending');
              form.find('.btn-enviar span').html(originalSubmitText);
              form.find('.btn-enviar').attr('disabled', false);
              /*
              setTimeout(function(){
                form.find('.msg').css('display', 'none');
                form.find('.msg p').removeClass('success').removeClass('error').html('');
              }, 10000);
              */
            }
          });

        }
      });
    }

    /**
     * FORM SOLICITUD ALTA ALUMNO
     */
    if($('form.solicitud-alta-alumno').length) {
      //console.log('SOLICITUD ALTA ALUMNO READY');

      const form_class = 'form.solicitud-alta-alumno';
      const form = $(form_class);

      //VALIDATE NIF/NIE
      form.find('input[name=NIF]').on('change', function() {
        var nif_nie = $(this).val();
        if(!validarNIF_NIE(nif_nie)){
          $(this).addClass('invalid');
          $(this).get(0).setCustomValidity('El NIF no es válido.');
        }else{
          $(this).removeClass('invalid');
          $(this).get(0).setCustomValidity('');
        }
      });

      //VALIDATE BIRTHDATE
      form.find('input.isValidDateDay, input.isValidDateMonth, input.isValidDateYear').on('change', function() {
        validateInputDate(form);
      });

      //EXTRACT VALUES FROM: PAIS, PROVINCIA, MUNICIPIO
      form.find('select.api-IdPais').on('change', function() {
        var fieldToUpdate = $(this).attr('data-fieldToUpdate');
        getProvincias2(this.value, fieldToUpdate);
      });

      form.find('select.api-IdProvincia').on('change', function() {
        var fieldToUpdate = $(this).attr('data-fieldToUpdate');
        getMunicipios2(this.value, fieldToUpdate);
      });

      //SELECT ESPECIALIDAD
      form.find('.datos-academicos').on('change', 'select.api-IdEspecialidad', function() {
        $('input[name=DesEspecialidad]').val($(this).find('option:selected').text());
      });

      //DOCUMENTS SENDING OPTION
      $(form_class+'.step-3').on('change', 'input[name=enviarMasTarde]', function() {
        if($(this).is(':checked')){
          $('.documentacion-si').addClass('hidden');
          $('.documentacion-no').removeClass('hidden');

          $(form_class+'.step-3').find('input[name=dni_pasaporte]').prop('required', false);
          $(form_class+'.step-3').find('input[name=titulo_universitario]').prop('required', false);
          $(form_class+'.step-3').find('input[name=ficha_solicitud]').prop('required', false);
        }else{
          $('.documentacion-no').addClass('hidden');
          $('.documentacion-si').removeClass('hidden');

          $(form_class+'.step-3').find('input[name=dni_pasaporte]').prop('required', true);
          $(form_class+'.step-3').find('input[name=titulo_universitario]').prop('required', true);
          $(form_class+'.step-3').find('input[name=ficha_solicitud]').prop('required', true);
        }
      });

      //PREVIOUS BUTTON
      form.find('.btn-anterior').on('click', function(event) {
        event.preventDefault();
        var step = $(this).data('step');
        showFormSteps(step);
      });

      //DOWNLOAD FICHA ALTA
      form.find('.ficha-alta-colegiacion').on('click', function(event) {
        //console.log('DOWNLOAD FICHA ALTA COLEGIACIÓN');

        event.preventDefault();

        if($(this).hasClass('enabled')){

          var formDataStep1 = $(form_class+'.step-1').serialize();
          var formDataStep2 = $(form_class+'.step-2').serialize();

          var allDataForms = 'action=download_ficha_alta_colegiado&' + formDataStep1 + '&' + formDataStep2;

          //console.log('ALL SETPS FORM DATA: ', allDataForms);

          $(this).find('span').text('generando PDF...').removeClass('enabled').addClass('disabled');

          $.ajax({
            data: allDataForms,
            type: 'POST',
            url: localize.ajaxurl,
            xhrFields: { responseType: 'blob' },
            success: function(data) {
              //console.log(data);

              var blob = new Blob([data]);
              var link = document.createElement('a');
              link.href = window.URL.createObjectURL(blob);
              link.download = "Ficha-solicitud-alta-Alumno.pdf";
              link.click();

            },
            error: function(data) {
              console.log(data);
              form.find('.msg').css('display', 'block');
              form.find('.msg p').addClass('error').html(data.responseJSON.data.message);
            },
            complete: function(){
              form.find('.ficha-alta-colegiacion span').text('descargar').removeClass('disabled').addClass('enabled');
            },
            done: function(){
              form.find('.ficha-alta-colegiacion span').text('descargar').removeClass('disabled').addClass('enabled');
            }
          });
        }

      });

      //SUBMIT FORM
      form.submit(function(event) {
        event.preventDefault();

        var step = $(this).data('step');
        //console.log('Step: ', step);
        var goToSetp = step + 1;

        if(step != 3){
          //console.log('GO TO STEP: ', goToSetp);
          showFormSteps(goToSetp);
        }else{
          var formDataStep1 = $(form_class+'.step-1').serialize();
          var formDataStep2 = $(form_class+'.step-2').serialize();

          const allDataForms = formDataStep1 + '&' + formDataStep2;
          form.find('input[name=formsdata]').val(allDataForms);
          
          //console.log('ALL SETPS FORM DATA: ', allDataForms);
          
          const originalSubmitText = form.find('.btn-enviar span').html();

          form.find('.btn-enviar').addClass('disabled sending');
          form.find('.btn-enviar span').html('Enviando...');
          form.find('.btn-enviar').attr('disabled', true);

          $.ajax({ 
            data: new FormData(this),
            type: 'POST',
            url: localize.ajaxurl,
            contentType: false,
            cache: false,
            processData:false,
            success: function(data) {
              //console.log(data);

              form.find('.msg').css('display', 'block');
              if(data.success && data.data.result == 'ok'){
                form.trigger('reset');
                $('.hide-on-success').hide();

                form.find('.msg p').removeClass('error').addClass('success').html(data.data.message);
              }

            },
            error: function(data) {
              console.log(data);
              $('.hide-on-success').hide();
              form.find('.msg').css('display', 'block');
              form.find('.msg p').addClass('error').html(data.responseJSON.data.message);
            },
            complete: function() {
              form.find('.btn-enviar').removeClass('disabled sending');
              form.find('.btn-enviar span').html(originalSubmitText);
              form.find('.btn-enviar').attr('disabled', false);
            }
          });

        }
      });
    }

    /**
     * FORM SOLICITUD ALTA USUARIO REGISTRADO
     */
    if($('form.solicitud-alta-usuario').length) {
      console.log('SOLICITUD ALTA USUARIO READY');

      const form_class = 'form.solicitud-alta-usuario';
      const form = $(form_class);

      //VALIDATE NIF/NIE
      form.find('input[name=NIF]').on('change', function() {
        var nif_nie = $(this).val();
        if(!validarNIF_NIE(nif_nie)){
          $(this).addClass('invalid');
          $(this).get(0).setCustomValidity('El NIF no es válido.');
        }else{
          $(this).removeClass('invalid');
          $(this).get(0).setCustomValidity('');
        }
      });

      //SUBMIT FORM
      form.submit(function(event) {
        event.preventDefault();
          
        const originalSubmitText = form.find('.btn-enviar span').html();

        form.find('.btn-enviar').addClass('disabled sending');
        form.find('.btn-enviar span').html('Enviando...');
        form.find('.btn-enviar').attr('disabled', true);

        $.ajax({ 
          data: new FormData(this),
          type: 'POST',
          url: localize.ajaxurl,
          contentType: false,
          cache: false,
          processData:false,
          success: function(data) {
            console.log(data);

            form.find('.msg').css('display', 'block');
            if(data.success && data.data.result == 'ok'){
              form.trigger('reset');
              $('.hide-on-success').hide();

              form.find('.msg p').removeClass('error').addClass('success').html(data.data.message);
            }else{
              form.find('.msg').css('display', 'block');
              form.find('.msg p').addClass('error').html(data.data.message);
            }

          },
          error: function(data) {
            console.log(data);
            $('.hide-on-success').hide();
            form.find('.msg').css('display', 'block');
            form.find('.msg p').addClass('error').html(data.responseJSON.data.message);
          },
          complete: function() {
            form.find('.btn-enviar').removeClass('disabled sending');
            form.find('.btn-enviar span').html(originalSubmitText);
            form.find('.btn-enviar').attr('disabled', false);
          }
        });

      });
    }

    /**
     * CONTACT FORM 7
     * DATE FIELD PLACEHOLDER
     */
    if($('form.wpcf7-form').length) {
      $('.wpcf7-form input[name^=fecha_]').on('focus', function(){
        $(this).attr('type', 'date');
      });

      $('.wpcf7-form input[name^=fecha_]').on('blur', function(){
        if(!$(this).val()){
          $(this).attr('type', 'text');
        }
      });

      /**
       * CHANGE SUBMIT BUTTON VALUE ON SENT
       */
      var btnEnviarCF7 = '';
      $('form.wpcf7-form').submit(function(event) {
        btnEnviarCF7 = $('.wpcf7-submit').val();
        $('.wpcf7-submit').val('Enviando...');
      });

      /**
       * CONTACT FORM 7
       * RETRIEVE THE ORIGINAL VALUE OF THE SUBMIT BUTTON
      */
      var wpcf7Form = document.querySelector( '.wpcf7-form' );

      wpcf7Form.addEventListener( 'wpcf7submit', function( event ) {
        document.querySelector( '.wpcf7-submit' ).value = btnEnviarCF7;
      }, false );
    }

    /**
     * FORM CANAL DENUNCIANTE
     */
    if($('form#canal-informante').length) {
      var link1 = 0;
      var link2 = 0;
      var link3 = 0;
      var totalLinksClicked = 0;

      const denunciaAnonimaObj = $('form#canal-informante input[name=denuncia_anonima]');
      const tipoPersonaObj = $('form#canal-informante input[name=tipo_persona]');
      const AceptarCondicionesObj = $('form#canal-informante input[name=aceptar_condiciones]');
      const condicionesLinkObj = $('.condiciones-links a');

      // ENABLE/DISABLE "Condiciones" CHECKBOX
      condicionesLinkObj.mousedown(function(event) {
        switch (event.which) {
          case 2:
            const ClickedLink = $(this).data('link');
            //console.log('ClickedLink: ', ClickedLink);
            $('.condiciones-links *[data-link="'+ClickedLink+'"]').trigger('click');
            break;
        }
      });

      condicionesLinkObj.on('click', function() {
        const ClickedLink = $(this).data('link');

        switch (ClickedLink) {
          case 1:
            link1 = 1;
            break;
          case 2:
            link2 = 1;
            break;
          case 3:
            link3 = 1;
            break;
        }

        totalLinksClicked = link1 + link2 + link3;

        console.log(totalLinksClicked);
        if(totalLinksClicked == 3){
          $('form#canal-informante .condiciones-links').find('input[name=aceptar_condiciones]').prop('disabled', false);
          $('form#canal-informante .condiciones-links label.container').removeClass('disabled');
        }
      });

      // ENABLE/DISABLE FORM DATA FIELDS
      AceptarCondicionesObj.on('click', function() {
        if($(this).is(':checked')){
          $('form#canal-informante .datos').show();
        }else{
          $('form#canal-informante .datos').hide();
        }
      });

      denunciaAnonimaObj.on('click', function() {
        if($(this).is(':checked')){
          //console.log('Denuncia anónima!');

          $('form#canal-informante input[name=razon_social]').prop('required', false);
          $('form#canal-informante input[name=doc_rep]').prop('required', false);
          $('form#canal-informante input[name=nombre]').prop('required', false);
          $('form#canal-informante input[name=apellidos]').prop('required', false);

          $('form#canal-informante input[name=telefono]').removeClass('invalid');
          $('form#canal-informante input[name=email]').removeClass('invalid');

          $('form#canal-informante input[name=telefono]').get(0).setCustomValidity('');
          $('form#canal-informante input[name=email]').get(0).setCustomValidity('');

          $('.datos__campos-tipo').hide();
          $('.datos__campos-denunciante').hide();
        }else{
          //console.log('Denuncia identificada!');

          if(tipoPersonaObj.val() == 2){
            $('form#canal-informante input[name=razon_social]').prop('required', true);
            $('form#canal-informante input[name=doc_rep]').prop('required', true);
          }
          $('form#canal-informante input[name=nombre]').prop('required', true);
          $('form#canal-informante input[name=apellidos]').prop('required', true);

          $('.datos__campos-tipo').show();
          $('.datos__campos-denunciante').show();
        }
      });

      tipoPersonaObj.on('click', function() {
        if($(this).val() == 1){
          //console.log('Persona física!');

          $('form#canal-informante input[name=razon_social]').prop('required', false);
          $('form#canal-informante input[name=doc_rep]').prop('required', false);
          $('.datos__campos-denunciante .razon-social').hide();
        }else if($(this).val() == 2){
          //console.log('Persona jurídica!');

          $('form#canal-informante input[name=razon_social]').prop('required', true);
          $('form#canal-informante input[name=doc_rep]').prop('required', true);
          $('.datos__campos-denunciante .razon-social').show();
        }
      });

      $('form#canal-informante input[type=file]').on('change', function(event) {
        var files = event.currentTarget.files;
        var HTMLFiles = '';
    
        for (var x in files) {
          //console.log(files);

          var filesize = (files[x].size/1024).toFixed(0); // KB

          if (files[x].name != "item" && typeof files[x].name != "undefined") {
            if(filesize > 2000) {
              HTMLFiles += '<p>'+files[x].name+' ('+filesize+' KB)'+' <span class="error">KO</span></p>';
            }else{
              HTMLFiles += '<p>'+files[x].name+' ('+filesize+' KB)'+' <span class="success">OK</span></p>';
            }
          }
        }
        $('.files_preview').html(HTMLFiles);
      });

      $('form#canal-informante input[name=telefono]').on('change', function(event){
        const isDenunciaAnonima = denunciaAnonimaObj.is(':checked');
        if(!isDenunciaAnonima) {
          $(this).removeClass('invalid');
          $('form#canal-informante input[name=email]').removeClass('invalid');

          $(this).get(0).setCustomValidity('');
          $('form#canal-informante input[name=email]').get(0).setCustomValidity('');
        }
      });

      $('form#canal-informante input[name=email]').on('change', function(event){
        const isDenunciaAnonima = denunciaAnonimaObj.is(':checked');
        if(!isDenunciaAnonima) {
          $(this).removeClass('invalid');
          $('form#canal-informante input[name=telefono]').removeClass('invalid');

          $(this).get(0).setCustomValidity('');
          $('form#canal-informante input[name=telefono]').get(0).setCustomValidity('');
        }
      });

      // SUBMIT FORM
      $('form#canal-informante').on('submit', function(event) {
        event.preventDefault();

        var isvalid = true;
        const form = $(this);
        const isDenunciaAnonima = denunciaAnonimaObj.is(':checked');
        const telefonoValue = form.find('input[name=telefono]').val();
        const emailValue = form.find('input[name=email]').val();

        if(telefonoValue == '' && emailValue == '' && !isDenunciaAnonima) {
          form.find('input[name=telefono]').addClass('invalid');
          form.find('input[name=email]').addClass('invalid');

          form.find('input[name=telefono]').get(0).setCustomValidity('Debe rellenar un teléfono o un email de contacto');
          form.find('input[name=email]').get(0).setCustomValidity('Debe rellenar un teléfono o un email de contacto');
          isvalid = false;
        }

        console.log('Valid form: ', isvalid);

        if(isvalid) {
          const originalSubmitText = form.find('button[type=submit] span').html();

          form.find('button[type=submit]').addClass('disabled sending');
          form.find('button[type=submit] span').html('Enviando...');
          form.find('button[type=submit]').prop('disabled', true);

          $.ajax({ 
            data: new FormData(this),
            type: 'POST',
            url: localize.ajaxurl,
            contentType: false,
            cache: false,
            processData:false,
            success: function(data) {
              console.log(data);

              form.find('.msg').css('display', 'block');
              if(data.success && data.data.result == 'ok'){
                form.trigger('reset');

                form.find('.msg p').addClass('success').html(data.data.message);
              }else {
                form.find('.msg p').addClass('error').html(data.data.message);
              }

            },
            error: function(error) {
              console.log(error);
              form.find('.msg').css('display', 'block');
              form.find('.msg p').addClass('error').html('No hemos podido enviar la solicitud, por favor, inténtelo de nuevo más tarde.');
            },
            complete: function() {
              form.find('button[type=submit]').removeClass('disabled sending');
              form.find('button[type=submit] span').html(originalSubmitText);
              form.find('button[type=submit]').prop('disabled', false);

              setTimeout(function(){
                form.find('.msg').css('display', 'none');
                form.find('.msg p').removeClass('success').removeClass('error').html('');
              }, 20000);
            }
          });
        }
      });
    }

    /**
     * ONLY NUMBERS INPUTS
     */
    $('input.onlynumbers').on('input', function (event) {
      $(this).val($(this).val().replace(/[^0-9]/g, ''));
    });

    /**
     * VERIFICACIÓN CERTIFICADO
     * SOLICITUD CERTIFICADO COLEGIACIÓN
     * PORTAL DEL COLEGIADO
     * FRONT-END
     */
    if($('form#verificar-certificados').length) {
      const form = $('form#verificar-certificados');

      form.submit(function(event) {
        //SEND TO WEBSERVICE
        event.preventDefault();

        console.log('DOWNLOAD CERTIFICADO VERIFICADO');

        form.find('.msg').css('display', 'none');
        form.find('.msg p').removeClass('success').removeClass('error').html('');

        const originalSubmitText = form.find('button[type=submit] span').html();

        form.find('button[type=submit]').addClass('disabled sending');
        form.find('button[type=submit] span').html('Descargando...');
        form.find('button[type=submit]').prop('disabled', true);

        let form_data = { 
          'action' : 'verificacion_certificados',
          'csv' : form.find('input[name=csv]').val(),
          'security' : localize.ajax_nonce
        }

        console.log(form_data);

        $.ajax({
          data: form_data,
          type: 'POST',
          url: localize.ajaxurl,
          xhrFields: { responseType: 'blob' },
          success: function(data) {
            //console.log(data);

            var blob = new Blob([data]);
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = "Certificado.pdf";
            link.click();
          },
          error: function(data, status, errorThrown) {
            console.log(data);

            form.find('.msg').css('display', 'block');
            form.find('.msg p').addClass('error').html(data.responseJSON.data.message);
          },
          complete: function(){
            console.log('COMPLETE');

            form.find('button[type=submit]').removeClass('disabled sending');
            form.find('button[type=submit] span').html(originalSubmitText);
            form.find('button[type=submit]').prop('disabled', false);

            setTimeout(function(){
              form.find('.msg').css('display', 'none');
              form.find('.msg p').removeClass('success').removeClass('error').html('');
            }, 10000);
          }
        });
      });
    }

    /**
     * EVENTON
     * LOAD EVENTON EVENT ANYWHERE VIA LIGHTBOX AJAX
     * FRONT-END
     */
    $('body').on('click','.eventon_anywhere.evoajax', function(event){
      console.log('EventOn: Open lightbox');

      event.preventDefault();

      var obj = $(this);
      var data = obj.data('sc');

      if( data.ev_uxval == '4') return;
    
      // NOTE: repeat_interval is already in SC
      data['evortl'] = 'no';
      if( 'id' in data ) data['event_id'] = data.id;
      data['ux_val'] = '3a';
      data['ajax_eventtop_show_content'] = false;

      obj.evo_cal_lightbox_trigger( data, obj, false);

      /*
        var obj = $(this);
        var data = obj.data('sc');
        var CAL = obj.closest('.ajde_evcal_calendar');

        if( data.ev_uxval == '4') return;

        event.preventDefault();
        event.stopPropagation();

        data['evortl'] = 'no';
        data['event_id'] = data.id;
        data['ux_val'] = '3a';
        data['ajax_eventtop_show_content'] = false;

        //console.log(CAL);
        eventon_open_event_lightbox( data, obj, CAL);
      */
		});

    /**
     * EVENTON
     * COPY EVENT LINK
     * FRONT-END
     */
		var evo_card_socialshare_html = '';
		$('body').on('click','.copy.evo_ss', function(event){
      event.preventDefault();
      event.stopPropagation();

      console.log('EventOn: Copy URL');

			const OBJ = $(this);
			const ROW = OBJ.closest('.evcal_evdata_row');
			var link = decodeURIComponent( OBJ.data('l') );
			navigator.clipboard.writeText( link );
			//copyTextToClipboard(link);

			evo_card_socialshare_html = ROW.html();
			ROW.html( "<p style='display:flex'><i class='fa fa-check marr10'></i> " + $(this).data("t") + "</p>");

			setTimeout(function(){
				ROW.html( evo_card_socialshare_html);
			},3000);
		});

    /**
     * VERIFICACIÓN DE VISADOS
     * FRONT-END
     */
    if($('form#verificar-visados').length) {
      const form = $('form#verificar-visados');

      //SUBMIT FORM
      form.on('submit', function(event) {
        event.preventDefault();
        
        console.log('SEND FORM VERIFICAR VISADO');

        $('.listado-docs').hide();
        form.find('.msg').css('display', 'none');
        form.find('.msg p').removeClass('success').removeClass('error').html('');

        const originalSubmitText = form.find('button[type=submit] span').html();

        form.find('button[type=submit]').addClass('disabled sending');
        form.find('button[type=submit] span').html('Buscando...');
        form.find('button[type=submit]').prop('disabled', true);

        //FORM DATA
        const formData = new FormData(this);
        formData.append('security', localize.ajax_nonce);
        formData.append('action', 'verificar_visados');

        $.ajax({
          url: localize.ajaxurl,
          type: 'POST',
          data: formData,
          contentType: false,
          processData: false,
          success: function(data) {
            //console.log(data);

            $('.listado-docs').show();
            $('#resultado').html(data.data.html);
          },
          error: function(data) {
            //console.log(data);

            form.find('.msg').css('display', 'block');
            form.find('.msg p').addClass('error').html(data.responseJSON.data.message);
          },
          complete: function() {
            //console.log('COMPLETE');

            form.find('button[type=submit]').removeClass('disabled sending');
            form.find('button[type=submit] span').html(originalSubmitText);
            form.find('button[type=submit]').prop('disabled', false);

            setTimeout(function(){
              form.find('.msg').css('display', 'none');
              form.find('.msg p').removeClass('success').removeClass('error').html('');
            }, 10000);
          }
        });
      });
    }

    /**
     * PORTAL COLEGIADO
     * AJAX LOGIN
     * PORTAL DEL COLEGIADO
     */
    if($('form#portal_ajax_login').length) {
      //ENABLE/DISABLE PASSWORD FIELD
      $('form#portal_ajax_login input[name=first_time_login]').on('click', function(event){
        var isFirstTimeLogin = $(this).is(':checked');
        //console.log('FIRST TIME LOGIN:', isFirstTimeLogin);

        if(isFirstTimeLogin){
          $('form#portal_ajax_login input[name=password]').prop('disabled', true).val('00000');
          $('form#portal_ajax_login p.password').hide();
          $('form#portal_ajax_login .login-content__instrucciones').show();
          //$('form#portal_ajax_login .login-content__instrucciones .header-collapse.off').trigger('click');
        }else{
          $('form#portal_ajax_login input[name=password]').prop('disabled', false).val('');
          $('form#portal_ajax_login p.password').show();
          $('form#portal_ajax_login .login-content__instrucciones').hide();
          //$('form#portal_ajax_login .login-content__instrucciones .header-collapse.on').trigger('click');
        }
      });

      //ENABLE/DISABLE SUBMIT
      $('form#portal_ajax_login input[name=username], form#portal_ajax_login input[name=password]').keyup(function(){
        const username = $('form#portal_ajax_login').find('input[name=username]').val();
        const password = $('form#portal_ajax_login').find('input[name=password]').val();

        if((username !== null && username !== '') && (password !== null && password !== '')){
          $('form#portal_ajax_login').find('button[type=submit]').prop('disabled', false);
          $('form#portal_ajax_login').find('button[type=submit]').removeClass('disabled');
        }else{
          $('form#portal_ajax_login').find('button[type=submit]').prop('disabled', true);
          $('form#portal_ajax_login').find('button[type=submit]').addClass('disabled');
        }
      });

      $('form#portal_ajax_login input[name=username], form#portal_ajax_login input[name=password]').on('focusout', function() {
        $(this).trigger('keyup');
      });

      $('form#portal_ajax_login').on('submit', function(event){
        event.preventDefault();

        var form = $(this);
        const originalSubmitText = form.find('button[type=submit] span').html();
        //var nonce = localize.ajax_nonce;
        var formData = { 
          'action': 'ajaxlogin',
          'username': form.find('input[name=username]').val(),
          'password': form.find('input[name=password]').val(),
          'security': form.find('input[name=nonce]').val(),
          'redirect_to': form.find('input[name=redirect_to]').val(),
          'origen': form.find('input[name=origen]').val()
        }

        console.log(formData);

        $.ajax({
          url: localize.ajaxurl,
          type: 'POST',
          dataType: 'json',
          data: formData,
          beforeSend : function ( xhr ) {
            form.find('.filter-actualidad__overlay').addClass('active');
            form.find('button[type=submit]').addClass('disabled sending');
            form.find('button[type=submit] span').html('Enviando...');
            form.find('button[type=submit]').prop('disabled', true);
          },
          success: function(data){
            console.log(data);

            if (data.loggedin == true){
              form.find('.msg').show();
              form.find('.msg').html('<p class="success">' + data.message + '</p>');

              switch (data.origen) {
                case 'formacion':
                  //if(data.change_pw){
                    //$('#portal_ajax_login, #portal_lost-password').hide();
                    //$('#portal_change-password').show();
                  //}else{
                    getFormacionInscripcionFrom();
                  //}
                  break;
                default:
                  document.location.href = data.redirect_to;
                  break;
              }
            }else{
              form.find('.msg').show();
              form.find('.msg').html('<p class="error">' + data.message + '</p>');
              
              form.find('.filter-actualidad__overlay').removeClass('active');
              form.find('button[type=submit]').removeClass('disabled sending');
              form.find('button[type=submit] span').html(originalSubmitText);
              form.find('button[type=submit]').prop('disabled', false);

              if(data.loginerror == 'nonce'){
                location.reload();
              }
            }
          },
          error: function(data){
            console.error(data);
          },
          complete: function(data){
            //console.log('DONE!');
          }
        });

        setTimeout(function(){
          $('.msg').fadeOut(800);
        }, 10000);
      });

      $('form#portal_ajax_login .login-lost-password a').on('click', function(event){
        event.preventDefault();
        $('form#portal_ajax_login').fadeOut(400, function(){
          $('form#portal_lost-password').fadeIn(800);
        });
      });

      $('form#portal_ajax_login .password .input-icon').on('click', function(event){
        if($('form#portal_ajax_login input[name=password]').attr('type') == 'password'){
          $('form#portal_ajax_login input[name=password]').attr('type', 'text');
        }else{
          $('form#portal_ajax_login input[name=password]').attr('type', 'password');
        }
      });

      setTimeout(function(){
        $('.msg').fadeOut(800);
      }, 10000);
    }

    /**
     * PORTAL COLEGIADO
     * RESET PASSWORD
     * PORTAL DEL COLEGIADO
     */
    if($('form#portal_lost-password').length) {
      //ENABLE/DISABLE SUBMIT
      $('form#portal_lost-password input[name=email]').keyup(function(){
        const email = $('form#portal_lost-password').find('input[name=email]').get(0);

        if(email.validity.valid) {
          $('form#portal_lost-password').find('button[type=submit]').prop('disabled', false);
          $('form#portal_lost-password').find('button[type=submit]').removeClass('disabled');
        }else{
          $('form#portal_lost-password').find('button[type=submit]').prop('disabled', true);
          $('form#portal_lost-password').find('button[type=submit]').addClass('disabled');
        }
      });

      $('form#portal_lost-password').on('submit', function(event){
        event.preventDefault();

        var form = $(this);
        const originalSubmitText = form.find('button[type=submit] span').html();
        var email = form.find('input[name=email]').val();
        var nonce = localize.ajax_nonce;
        
        var formData = {
          'action':'login_lost_password',
          'email':email,
          'security':nonce,
          'origen': form.find('input[name=origen]').val()
        }

        $.ajax({
          url : localize.ajaxurl, // AJAX handler
          data: formData,
          type : 'POST',
          beforeSend : function ( xhr ) {
            form.find('button[type=submit]').addClass('disabled sending');
            form.find('button[type=submit] span').html('Enviando...');
            form.find('button[type=submit]').prop('disabled', true);
          },
          success : function( data ){
            //console.log(data);
            
            if(data.data.result == 'ok'){
              form.find('.msg p').addClass('success').html(data.data.message);
              setTimeout(function(){
                $('form#portal_lost-password').fadeOut(400, function(){
                  //$('form#portal_login').fadeIn(800);
                  $('form#portal_ajax_login').fadeIn(800);
                });
              }, 6000);
            }else{
              form.find('.msg p').addClass('error').html(data.data.message);
            }
            form.find('.msg').show();
            
            form.find('button[type=submit]').removeClass('disabled sending');
            form.find('button[type=submit] span').html(originalSubmitText);
            form.find('button[type=submit]').prop('disabled', false);

            form.trigger('reset');
          }
        })
        .done(function(data) {
          //console.log('DONE!');

          form.find('input[type=submit]').prop('disabled', false);
          setTimeout(function(){
            $('.msg').fadeOut(800);
          }, 5000);
        })
        .fail(function(error) {
          console.error(error);

          form.find('input[type=submit]').val(originalSubmitText);
        });
      });
      $('form#portal_lost-password .volver').on('click', function(event){
        event.preventDefault();
        $('form#portal_lost-password').fadeOut(400, function(){
          $('form#portal_ajax_login').fadeIn(800);
        });
      });
    }

    /**
     * PORTAL COLEGIADO
     * LOGIN CHANGE PASSWORD
     * PORTAL DEL COLEGIADO
     */
    if($('form#portal_change-password').length) {

      //VALIDATE REPEAT PASSWORD WHEN WRITING
      $('form#portal_change-password input[name=newpassword]').keyup(function(){
        var newPassword = $('form#portal_change-password').find('input[name=newpassword]').val();

        var RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&+#<>;_\-=.\\/])[A-Za-z\d@$!%*?&+#<>;_\-=.\\/]{8,}$/.test(newPassword);
        //console.log(RegExp);

        if(!RegExp){
          //console.log('Invalid password');

          $(this).addClass('invalid');
          $(this).get(0).setCustomValidity('La contraseña debe tener una longitud de mínima 8 caracteres y contener al menos una mayúscula, una minúscula un dígito y un carácter especial. Los caracteres especiales válidos son: @$!%*?&+#<>;_-=.\/');
        }else{
          //console.log('Valid password');

          $(this).removeClass('invalid');
          $(this).get(0).setCustomValidity('');
        }
      });
      $('form#portal_change-password input[name=repeat-newpassword]').keyup(function(){
        var newPassword = $('form#portal_change-password').find('input[name=newpassword]').val();
        var repeatNewPassword = $('form#portal_change-password').find('input[name=repeat-newpassword]').val();

        if(newPassword != repeatNewPassword){
          //console.log('Las contraseñas no coinciden.');

          $(this).addClass('invalid');
          $(this).get(0).setCustomValidity('Las contraseñas no coinciden.');
          $('form#portal_change-password').trigger('submit');
        }else{
          $(this).removeClass('invalid');
          $(this).get(0).setCustomValidity('');
        }
      });

      //ENABLE/DISABLE SUBMIT
      /*
      $('form#portal_change-password input[type=password]').keyup(function(){
        const oldpassword = $('form#portal_change-password').find('input[name=oldpassword]').get(0);
        const newpassword = $('form#portal_change-password').find('input[name=newpassword]').get(0);
        const repeatNewPassword = $('form#portal_change-password').find('input[name=repeat-newpassword]').get(0);

        if(oldpassword.validity.valid && newpassword.validity.valid && repeatNewPassword.validity.valid) {
          $('form#portal_change-password').find('button[type=submit]').prop('disabled', false);
          $('form#portal_change-password').find('button[type=submit]').removeClass('disabled');
        }else{
          $('form#portal_change-password').find('button[type=submit]').prop('disabled', true);
          $('form#portal_change-password').find('button[type=submit]').addClass('disabled');
        }
      });
      */

      $('form#portal_change-password').on('submit', function(event){
        event.preventDefault();

        console.log('CHANGE PASSWORD SUBMIT');

        var form = $(this);
        const originalSubmitText = form.find('button[type=submit] span').html();
        var oldPassword = form.find('input[name=oldpassword]').val();
        var newPassword = form.find('input[name=newpassword]').val();
        var repeatNewPassword = form.find('input[name=repeat-newpassword]').val();
        var nonce = localize.ajax_nonce;

        //VALIDATE REPEAT PASSWORD ON SENDING
        if(newPassword != repeatNewPassword){
          //console.log('Las contraseñas no coinciden.');

          form.find('input[name=repeat-newpassword]').get(0).setCustomValidity('Las contraseñas no coinciden.');
          return;
        }

        var formData = {
          'action':'login_change_password',
          'oldpass':oldPassword,
          'newpass':newPassword,
          'security':nonce,
          'origen': form.find('input[name=origen]').val()
        }

        $.ajax({
          url : localize.ajaxurl, // AJAX handler
          data: formData,
          type : 'POST',
          beforeSend : function ( xhr ) {
            form.find('button[type=submit]').addClass('disabled sending');
            form.find('button[type=submit] span').html('Enviando...');
            form.find('button[type=submit]').prop('disabled', true);
          },
          success : function( data ){
            //console.log(data);
            
            if(data.data.result == 'ok'){
              form.find('.msg p').addClass('success').html(data.data.message);
              setTimeout(function(){
                //location.href = localize.area_privada;
                $('form#portal_change-password').fadeOut(400, function(){
                  //$('form#portal_login').fadeIn(800);
                  $('form#portal_ajax_login').fadeIn(800);
                });
              }, 6000);
            }else{
              form.find('.msg p').addClass('error').html(data.data.message);
            }
            form.find('.msg').show();
            
            form.find('button[type=submit]').removeClass('disabled sending');
            form.find('button[type=submit] span').html(originalSubmitText);
            form.find('button[type=submit]').prop('disabled', false);
            
            form.trigger('reset');
          }
        })
        .done(function(data) {
          //console.log('DONE!');

          form.find('input[type=submit]').prop('disabled', false);
          setTimeout(function(){
            $('.msg').fadeOut(800);
          }, 5000);
        })
        .fail(function(error) {
          console.error(error);
          form.find('input[type=submit]').val(originalSubmitText);
        });

      });
    }

  }); //END JQUERY READY

  //PAGE LOADER
  $(window).on('load', function() {
    $('body').css('cursor', 'inherit');
    $('#page-loader').fadeOut(500, function() {
      $('body').removeClass('loader');
    });
  });

  /**
   * AUX FUNCTIONS
   */

  //FORMAT NUMBER LEADING ZEROS
  window.getNumberWithLeadingZero = function (elem, zeros) {
    if(elem.value != ''){
      elem.value = elem.value.padStart(zeros, '0');
    }
  }

  //SELECT2 SHOW REQUIRED FIELD ERROR
  window.showSelect2FieldError = function (elem){
    var elemName = jQuery(elem).prop('name');
    //console.log('showSelect2FieldError: ', elemName);
    jQuery('select[name='+elemName+']').parent().find('span.select2.select2-container.select2-container--default').css('box-shadow', '0 0 0 0.2rem #b64d34');
  }

  //SELECT2 HIDE REQUIRED FIELD ERROR
  window.removeSelect2FieldError = function (elem){
    var elemName = jQuery(elem).prop('name');
    //console.log('removeSelect2FieldError: ', elemName);
    jQuery('select[name='+elemName+']').parent().find('span.select2.select2-container.select2-container--default').css('box-shadow', 'none');
  }

  //COPY TEXT TO CLIPBOARD
  function copyTextToClipboard(text) {
    var dummy = document.createElement('input');
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    
    document.execCommand('copy');
    document.body.removeChild(dummy);
  }

  function validarNIF_NIE(nif_nie){
    nif_nie = nif_nie.toUpperCase();

    var empieza_por = /[XYZ]/;

    if(empieza_por.test(nif_nie) === true){
      //console.log('Validar NIE: ', nif_nie);
      return validarNIE(nif_nie);
    }else{
      //console.log('Validar NIF: ', nif_nie);
      return validarNIF(nif_nie);
    }
  }

  function validarNIE(dni) {
    var numero, letraNIF, letra;
    var expresion_regular_dni = /^[XYZ]?\d{5,8}[A-Z]$/;

    dni = dni.toUpperCase();

    if(expresion_regular_dni.test(dni) === true){
        numero = dni.substr(0,dni.length-1);
        numero = numero.replace('X', 0);
        numero = numero.replace('Y', 1);
        numero = numero.replace('Z', 2);
        letraNIF = dni.substr(dni.length-1, 1);
        numero = numero % 23;
        letra = 'TRWAGMYFPDXBNJZSQVHLCKET';
        letra = letra.substring(numero, numero+1);
        if (letra != letraNIF) {
            //alert('Dni erroneo, la letra del NIF no se corresponde');
            return false;
        }else{
            //alert('Dni correcto');
            return true;
        }
    }else{
        //alert('Dni erroneo, formato no válido');
        return false;
    }
  }

  function validarNIF(nif){
    if (nif.length==0){
        return false;
    }else{
      var miLetra = "";
      var miLetra = nif.substr(nif.length-1, 1)
      miLetra = miLetra.toUpperCase();
      if (miLetra == calcularletraNIF(nif.substring(0, nif.length-1))){
        return true;
      }else{
        return false;
      }
    }
  }

  function calcularletraNIF(nif){
    var cadena = 'TRWAGMYFPDXBNJZSQVHLCKET';
    var posicion = nif % 23;
    return cadena.charAt(posicion);
  }

  function validarDigitoControl(iban) {
    var entidad = iban.substring(4, 8);
    var sucursal = iban.substring(8, 12);
    var dc = iban.substring(12, 14);
    var numerocuenta = iban.substring(14);
    var codigoPaisIBAN = iban.substring(0, 2);
    var digitoControlIBAN = iban.substring(2, 4);
    var codigoIBANRetorno;

    if (validarCuentaBancaria(entidad, sucursal, dc, numerocuenta)){
      codigoIBANRetorno = calcularIBAN(entidad+sucursal+dc+numerocuenta, codigoPaisIBAN);
      if (codigoIBANRetorno != digitoControlIBAN){
        return false;
      }
    }else{
      return false;
    }
    return true;
  }

  function calcularIBAN(numerocuenta, codigopais) {
      //Conversión de letras por números
      //A=10 B=11 C=12 D=13 E=14
      //F=15 G=16 H=17 I=18 J=19
      //K=20 L=21 M=22 N=23 O=24
      //P=25 Q=26 R=27 S=28 T=29
      //U=30 V=31 W=32 X=33 Y=34
      //Z=35
                      
      if (codigopais == null || codigopais.length != 2)
          return null;
      else {
          var Aux;
          var CaracteresSiguientes;
          var TmpInt;
          var CaracteresSiguientes;

          numerocuenta = numerocuenta + (codigopais.charCodeAt(0) - 55).toString() + (codigopais.charCodeAt(1) - 55).toString() + "00";

          //Hay que calcular el módulo 97 del valor contenido en número de cuenta
          //Como el número es muy grande vamos calculando módulos 97 de 9 en 9 dígitos
          //Lo que se hace es calcular el módulo 97 y al resto se le añaden 7 u 8 dígitos en función de que el resto sea de 1 ó 2 dígitos
          //Y así sucesivamente hasta tratar todos los dígitos

          TmpInt = parseInt(numerocuenta.substring(0, 9), 10) % 97;

          if (TmpInt < 10)
              Aux = "0";
          else
              Aux = "";

          Aux=Aux + TmpInt.toString();
          numerocuenta = numerocuenta.substring(9);

          while (numerocuenta!="") {
              if (parseInt(Aux, 10) < 10)
                  CaracteresSiguientes = 8;
              else
                  CaracteresSiguientes = 7;

              if (numerocuenta.length<CaracteresSiguientes) {
                  Aux=Aux + numerocuenta;
                  numerocuenta="";
              }
              else {
                  Aux=Aux + numerocuenta.substring(0, CaracteresSiguientes);
                  numerocuenta=numerocuenta.substring(CaracteresSiguientes);
              }

              TmpInt = parseInt(Aux, 10) % 97;

              if (TmpInt < 10)
                  Aux = "0";
              else
                  Aux = "";

              Aux=Aux + TmpInt.toString();
          }

          TmpInt = 98 - parseInt(Aux, 10);

          if (TmpInt<10)
              //return codigopais + "0" + TmpInt.toString();
              return "0" + TmpInt.toString();
          else
              //return codigopais + TmpInt.toString();
              return TmpInt.toString();

      }
  }

  function validarCuentaBancaria(entidad, sucursal, dc, numerocuenta) {
      if (entidad == null || sucursal == null || dc == null || numerocuenta == null) {
          alert("Por favor, introduzca todos los datos de su cuenta bancaria.");
          return false;
      } else {
          if (entidad.length != 4 || sucursal.length != 4 || dc.length != 2 || numerocuenta.length != 10) {
              alert("Por favor, introduzca correctamente los datos de su cuenta bancaria.");
              return false;
          } else {
              if (!esNumerico(entidad) || !esNumerico(sucursal) || !esNumerico(dc) || !esNumerico(numerocuenta)) {
                  alert("Por favor, introduzca correctamente los datos de su cuenta bancaria. No son numéricos");
                  return false;
              } else {
                  if (!(obtenerDigitoControl("00" + entidad + sucursal) == parseInt(dc.charAt(0))) || !(obtenerDigitoControl(numerocuenta) == parseInt(dc.charAt(1)))) {
                      alert("Los dígitos de control no se corresponden con los demás números de la cuenta");
                      return false;
                  } else {
                      return true;
                  }
              }
          }
      }
  }

  function obtenerDigitoControl(valor) {
      var valores = new Array(1, 2, 4, 8, 5, 10, 9, 7, 3, 6);
      var control = 0;
      for (var i = 0; i <= 9; i++)
          control += parseInt(valor.charAt(i)) * valores[i];
      control = 11 - (control % 11);
      if (control == 11) control = 0;
      else if (control == 10) control = 1;
      return control;
  }

  function esNumerico(valor) {
      var cad = valor.toString();
      for (var i = 0; i < cad.length; i++) {
          var caracter = cad.charAt(i);
          if (caracter < "0" || caracter > "9")
              return false;
      }
      return true;
  }

  function isValidDate(date){
    let dateformat = /^(0?[1-9]|1[0-2])[\/](0?[1-9]|[1-2][0-9]|3[01])[\/]\d{4}$/g;

    // Matching the date through regular expression      
    if (date.match(dateformat)) {
      let operator = date.split('/');

      // Extract the string into month, date and year      
      let datepart = [];
      if (operator.length > 1) {
        datepart = date.split('/');
      }
      let month = parseInt(datepart[0]);
      let day = parseInt(datepart[1]);
      let year = parseInt(datepart[2]);

      // Create a list of days of a month      
      let ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      if (month == 1 || month > 2) {
        if (day > ListofDays[month - 1]) {
          //to check if the date is out of range     
          return false;
        }
      } else if (month == 2) {
        let leapYear = false;
        if ((!(year % 4) && year % 100) || !(year % 400)){
          leapYear = true;
        }
        if ((leapYear == false) && (day >= 29)){
            return false;
        }else{
          if ((leapYear == true) && (day > 29)) {
            return false;
          }
        }
      }
    } else {
      return false;
    }
    return true;
  }

  function getURLAnchor() {
    return (document.URL.split('#').length > 1) ? document.URL.split('#')[1] : null;
  }

})(jQuery);
